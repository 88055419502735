/// Error pages: 401, 404, 422, 500

@use "../variables" as *;

// <body>
.error-page {
  main {
    text-align: center;
  }

  h1 {
    color: $tt-blue;

    i {
      font-size: 6rem;
    }
  }
}
