/// Labels

@use "../variables" as *;

// Outlined style
.label {
  display: inline-flex;
  gap: $inline-gap;
  align-items: center;
  justify-content: center;
  min-width: $label-size;
  height: $label-size;
  padding: 0 $inline-gap-lg;
  font-size: $label-font-size;
  font-weight: 600; // Independent of parent
  line-height: 1; // Independent of parent
  color: var(--label-theme, $label-default-theme);
  text-transform: lowercase;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--label-theme, $label-default-theme) $theme-tint-fx;
  border-radius: $border-radius-circle;

  /// Themes

  @each $name, $color in $label-themes {
    &.-#{$name} {
      --label-theme: #{$color};
    }
  }
}
