/// Comments: feedback from coach or friend, internal message

// @markup
// <article>.comment
//   <img|span>.avatar
//   .comment__header
//     <h4>
//       (<span>.label)
//     (<time>)
//   .comment__body

@use "../variables" as *;

.comment {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0 $inline-gap-lg;
}

.comment__header {
  display: flex;
  flex-wrap: wrap;
  gap: $line-height-xs var(--tt-gap);
  place-content: center space-between;
  align-items: baseline;

  h4 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // time {}
}

.comment__body {
  grid-column: 2 / 3; // or 1 / 3?
  // padding-inline: var(--tt-gap);
  // margin-block-start: var(--tt-gap-sm);

  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

/// Groups

.comment-list {
  display: grid;
  gap: $gap-lg;
}
