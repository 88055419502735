/// Events: notifications

@use "@functions/map" as map;
@use "../variables" as *;

$_event-result-theme: map.deep-get($card-themes, "event", "result");
$_event-unread-theme: map.deep-get($card-themes, "event", "unread");

// <li>
// @extend .card
.event {
  // Highlight assessment results
  &.-result {
    --THEME: #{$_event-result-theme};
  }

  // Highlight events that haven't been opened yet
  &.-unread {
    --THEME: #{$_event-unread-theme};

    font-weight: 600;
  }
}
