// Bootstrap 3 components that we still depend on
// See app/webpack/packs/programs.js:9
// Note: order of @import is important!
// stylelint-disable

//!
// Bootstrap v3.4.1 (https://getbootstrap.com/)
// Copyright 2011-2019 Twitter, Inc.
// Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)

// TrainTool variables for Bootstrap
@use "@variables/color-palette" as c;
@use "./_variables/theme" as th;

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins/opacity";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins/reset-filter";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";

// Collapse: ask feedback again
@import "~bootstrap-sass/assets/stylesheets/bootstrap/component-animations";

// Dropdown menus in desktop navbar
$dropdown-bg: c.$white;
$dropdown-border: c.$white;
$dropdown-fallback-border: c.$white;
$dropdown-divider-bg: c.$gray-200;
$dropdown-link-color: th.$theme-text;
$dropdown-link-hover-color: th.$theme-button;
$dropdown-link-hover-bg: c.$gray-100;
$dropdown-header-color: th.$theme-heading;
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins/nav-divider";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";

// Dialogs: replace with <dialog>
$modal-content-fallback-border-color: transparent;
$modal-content-border-color: transparent;
$modal-backdrop-bg: c.$white;
$modal-backdrop-opacity: 0.6;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/modals";
