/// Header / Page title

@use "../variables" as *;

// <header> > <h1> has page title
// <nav> has program navigation: Programs title, Notifications
.header {
  display: flex;
  gap: var(--tt-gap);
  justify-content: center;
  padding: var(--tt-gap) var(--tt-gap) 0;

  // RolePlay title, Preparation, Friends
  // Programs index has icon
  // Note: also applies inside .well
  h1 {
    // Won't work in Firefox https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
    &:has(i) {
      display: flex;
      gap: $inline-gap;
      align-items: center;
    }
  }

  &.-sidebar {
    --header-size: #{$layout-max-width};
  }

  &.-overview {
    --header-size: #{$layout-main-max-width};
  }

  &.-fullscreen {
    --header-size: 100%;

    h1 {
      margin-inline-start: calc($button-min-width + $inline-gap);
      font-size: $font-size;
      color: $theme-neutral;
    }
  }
}

// Can have description, e.g. Skills, Contacts
.header-inner {
  flex: 0 $layout-content-ratio var(--header-size, $layout-content-max-width);
}

/// Misc

.account-header {
  margin-block-end: var(--tt-gap);
}
