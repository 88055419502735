/// TrainTool variables

// Notes
// *1 Size `base` variables in px: only use for ad-hoc calculations or when relative scaling isn't necessary
// *2 `$var--px-base`: double--dashes make variables stand out, making sure px are used with intention (see *1)

/// Stylelint: exclude rules that allow human-friendly sorting, grouping and alignment
// stylelint-disable scss/dollar-variable-colon-space-after
// stylelint-disable scss/dollar-variable-empty-line-before
// stylelint-disable scss/double-slash-comment-empty-line-before

@use "sass:math";
@use "@variables/color-palette" as *;
@forward "@variables/color-palette";
@use "@functions/color-transparency" as transparency;
@use "@functions/units" as unit;


/// Local settings

// Values for adjusting sizes using Fibonacci sequence + 1.5
$_size-mlg:                     1.5;
$_size-lg:                      2;
$_size-xl:                      3;
$_size-2xl:                     5;
$_size-2xs:                     math.div(1, $_size-2xl);
$_size-xs:                      math.div(1, $_size-xl);
$_size-sm:                      math.div(1, $_size-lg);
$_size-msm:                     math.div(1, $_size-mlg);

// Element aspect ratios for mostly videos and images
$ratio-16x9:                    math.div(9, 16);
$ratio-4x3:                     math.div(3, 4);


/// Media query breakpoints
// @TODO: style elements responsive as much as possible, mq's should ideally not be necessary

// Extra small screen / phone
$screen-xs:                     480px;  // 30rem
// Small screen / tablet
$screen-sm:                     768px;  // 48rem
// Medium screen / desktop
// $screen-md:                     992px;  // 62rem
// Large screen / wide desktop
// $screen-lg:                     1200px; // 75rem

$screen-height-md:              800px;  // Sticky sidebar


/// Z-indeces - Bootstrap 5 and custom values

$zindex-sticky:                 1010;           // =TT // 1020 =BS
$zindex-fixed:                  1020;           // =TT // 1030 =BS
$zindex-dropdown:               1030;           // =TT // 1000 =BS
// $zindex-offcanvas-backdrop:     1040;           // =BS
// $zindex-offcanvas:              1045;           // =BS
$zindex-modal-backdrop:         1050;           // =BS
$zindex-modal:                  1055;           // =BS
$zindex-popover:                1070;           // =BS
// $zindex-tooltip:                1080;           // =BS
// $zindex-toast:                  1090;           // =BS


/// Typography

// stylelint-disable value-keyword-case
$_font-family-system:           system-ui, "Segoe UI", Roboto;
$_font-family-sans:             Helvetica, Arial, sans-serif;
$_font-family-emoji:            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$_font-family-fallback:         $_font-family-system, $_font-family-sans, $_font-family-emoji;

$font-family:                   Poppins, $_font-family-fallback;
$font-family-default:           $_font-family-fallback;
$font-family-headings:          Poppins, $_font-family-fallback;
// stylelint-enable value-keyword-case


// Base sizes for all font-size, widths, heights, paddings, margins, etc. (*1 *2)
$font-size--px-base:            15px;   // Becomes 1rem
$font-line-height--px-base:     22.5px; // 1.5

// HTML font size, becomes the new 1rem
// Always calculated from 16px = browser default!
$font-size-root:                unit.rem($font-size--px-base, 16px);

// Font sizes - use these in stylesheets
$font-size:                     unit.rem($font-size--px-base, $font-size--px-base);
$font-size-xs:                  unit.rem(12px, $font-size--px-base); // Badges, labels
$font-size-sm:                  unit.rem(14px, $font-size--px-base);
$font-size-lg:                  unit.rem(18px, $font-size--px-base);
$font-size-xl:                  unit.rem(20px, $font-size--px-base);

$font-size-h1:                  unit.em(32px, $font-size--px-base);
$font-size-h2:                  unit.em(22px, $font-size--px-base);
$font-size-h3:                  unit.em(18px, $font-size--px-base);
$font-size-h4:                  unit.em(16px, $font-size--px-base);
$font-size-h5:                  unit.em(15px, $font-size--px-base);
$font-size-h6:                  unit.em(14px, $font-size--px-base);


// Unitless: applying to text elements
// body text:   1.5
$font-line-height:              unit.strip(
                                  unit.em($font-line-height--px-base, $font-size--px-base)
                                );
// h1, h2:      1.0
$heading-line-height-lg:        unit.strip(unit.em(15px, $font-size--px-base));
// h3:          1.2
$heading-line-height-md:        unit.strip(unit.em(18px, $font-size--px-base));
// h4, h5, h6:  1.3333
$heading-line-height-sm:        unit.strip(unit.em(20px, $font-size--px-base));

// rem values: used for (text) element margins, e.g. (un)ordered lists
$line-height:                   unit.rem($font-line-height, 1px);
$line-height-xs:                $line-height * $_size-xs;
$line-height-sm:                $line-height * $_size-sm;
$line-height-lg:                $line-height * $_size-lg;


// Space between inline elements, e.g. icon + text
$inline-gap:                    0.5ch;          // 1~2 space (\s) characters or ~6px
$inline-gap-lg:                 $inline-gap * 2;


/// Layout

// Base spacing (*1 *2)
$gap--px-base:                  $font-size--px-base;

$gap:                           unit.rem($gap--px-base, $font-size--px-base);
// $gap-2xs:                       $gap * $_size-2xs;
$gap-xs:                        $gap * $_size-xs;
$gap-sm:                        $gap * $_size-sm;
// $gap-ms:                        $gap * $_size-ms;
// $gap-ml:                        $gap * $_size-ml;
$gap-lg:                        $gap * $_size-lg;
// $gap-xl:                        $gap * $_size-xl;
$gap-2xl:                       $gap * $_size-2xl;


// Content inside main layout columns, containers etc.
// Sizes in px, independent of font-size or scaling
$layout-main-ratio:             10;
$layout-content-ratio:           7;
$layout-sidebar-ratio:           4;
$layout-main-max-width:         $layout-main-ratio * 100px;
$layout-content-max-width:      $layout-content-ratio * 100px;
$layout-sidebar-max-width:      $layout-sidebar-ratio * 100px;

// Layout columns: wide content or small content + optional sidebar
// 700px + 30px + 400px = 1130px
// stylelint-disable scss/operator-no-newline-before
$layout-max-width: (
                                $layout-content-max-width
                                + $gap--px-base * 2
                                + $layout-sidebar-max-width
);
// stylelint-enable scss/operator-no-newline-before

$component-max-width:           unit.rem(480px, $font-size--px-base);
$component-max-height:          $component-max-width * $ratio-4x3;
$component-max-width-sm:        unit.rem(360px, $font-size--px-base);
$component-max-height-sm:       $component-max-width-sm * $ratio-4x3;


// Max width for paragraphs and other texts, for readability
$text-max-width:                70ch;   // ~685px


/// Media: images and videos

// Note: legacy (~pre 2020) media can have weird sizes/ratio: 718x404 or 720x404 != 16:9
// Note: 700px not a true 16:9 resolution, which should be divisible by 8
$media-width:                   700px; // unit.rem(700px, $font-size--px-base);
$media-height:                  $media-width * $ratio-16x9;
$media-ratio-16x9:              100% * $ratio-16x9;
$media-ratio-4x3:               100% * $ratio-4x3;
$media-bg:                      $black;
$media-shadow:                  0 4px 8px transparency.black(20%);

// TrainTool logo
// Note: sizes should also be set as html attributes: <img width="" height="">!
$logo-size:                     42px;   // Applied to height. Actually 46px, but letting balloon arrow overflow
// $logo-width:                    42px;
// $logo-height:                   46px;
$logo-max-height:               64px;


/// Borders
// E.g. on alert and buttons

$border-width:                  1px;
$border-width-retina:           1.5px;  // Note: 1.5px looks off on non-retina and Windows Firefox
$border-color:                  $tt-gray-300;

$border-radius:                 10px;
$border-radius-xxs:             $border-radius * $_size-2xs;
// $border-radius-xs:              $border-radius * $_size-xs;
$border-radius-sm:              $border-radius * $_size-sm;
$border-radius-md:              $border-radius * $_size-mlg;
$border-radius-lg:              $border-radius * $_size-lg;
// $border-radius-xl:              $border-radius * $_size-xl;
// $border-radius-xxl:             $border-radius * $_size-2xl;
$border-radius-circle:          100px;


/// Shadows

// When to remove or disable shadow
$no-shadow:                     0 0; // @TODO: why not `none`?

// Alerts, cards, other?
$component-shadow:              0 1px 4px transparency.black(5%),
                                0 7px 16px transparency.black(5%);
$component-active-shadow:       0 3px 8px transparency.black(16%),
                                0 6px 18px transparency.black(20%);

// Big blocks, like navigation
$block-box-shadow:              0 1px 2px -1px transparency.black(32%);
$block-box-shadow-up:           0 -1px 2px -1px transparency.black(32%);
$block-box-shadow-hover:        0 1px 4px transparency.black(32%);

// Overlay elements, e.g. modals, dropdowns
$overlay-shadow:                0 2px 8px transparency.black(24%);


/// Gradients

// Striped background gradients
$gradient-stripe-size:          1rem;
$gradient-stripe-angle:         120deg;

$gradient-stripes-dark:         repeating-linear-gradient(
                                  $gradient-stripe-angle,
                                  transparency.black(2%) 0,
                                  transparency.black(2%) $gradient-stripe-size,
                                  transparency.black(0%) $gradient-stripe-size,
                                  transparency.black(0%) $gradient-stripe-size * 2
                                );

$gradient-stripes-light:        repeating-linear-gradient(
                                  $gradient-stripe-angle,
                                  transparency.white(12%) 0,
                                  transparency.white(12%) $gradient-stripe-size,
                                  transparency.white(0%) $gradient-stripe-size,
                                  transparency.white(0%) $gradient-stripe-size * 2
                                );

$gradient-glossy:               linear-gradient(
                                  transparency.white(24%),
                                  transparency.white(8%),
                                  transparency.white(16%)
                                );

// Animated progress bars, needs adjusting background-size
$progress-animation:            progress-bar 0.8s linear infinite;
$progress-animation-size:       math.div(
                                  $gradient-stripe-size * 2,
                                  math.cos(90deg - math.abs($gradient-stripe-angle))
                                ) 100%;


/// Animations

$animation-speed:               240ms;
$animation-speed-fast:          $animation-speed * 0.5;
$animation-speed-slow:          $animation-speed * 2;
$animation-speed-xslow:         $animation-speed * 4;
$animation-timing:              ease-in-out;
$interaction-animation-speed:   120ms;
