/// Criteria

// @markup
// Two ways of displaying criteria (list) and criterion (text):
// -
// 1. Criteria as form with Yes/No buttons
// ul.criteria
//   li.card
//     .card__header
//       .criterion
//         span{criterion index}
//         {criterion text}
//     .card__body
//       (.button-bar)
//         (.choice)
//           label
//             input[type="radio"]      Hidden
//             label.button
//               i.ph-[check|minus]-circle
//               {Yes/No text}
// -
// 2. Criteria displayed as result
// .card
//   .card__body
//     ul.criteria
//       li.criterion
//         i.ph-[check|minus]-circle
//         {criterion text}
//         [.button]                    Play video (CriteriaHighlight)

@use "../variables" as *;

// <ul>
.criteria {
  display: grid;
  gap: $line-height-sm;
  padding: 0;
  margin: 0;
  list-style: none;
}

// <li|div>
.criterion {
  display: grid;
  grid-template-columns: auto 1fr auto; // 2nd auto: Alix visual feedback play button
  gap: $inline-gap-lg;
  word-break: break-word;

  // Result
  > i {
    font-size: $font-size-xl;
    color: var(--icon, inherit);
    transform: translateY(1px);

    &[class*="minus"] {
      --icon: #{$theme-neutral};
    }

    &[class*="check"] {
      --icon: #{$theme-success};
    }
  }
}
