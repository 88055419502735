@use "../variables" as *;

// <ul>
.a11y-menu {
  position: fixed;
  top: $gap-sm;
  left: -200%;
  z-index: $zindex-fixed;
  padding: $gap-xs $gap;
  color: $white;
  background: $theme-accent;

  &:focus {
    left: $gap-sm;
  }
}
