/// Preview a program as an admin, coach, or anonymous via shared link
// .web-preview
//   (<a>)      "Back to admin"
//   .preview-title
//   <a>        "Info & share"

@use "../variables" as *;

// <body>
.preview {
  // = .navigation
  .web-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-block: 0;
    background: $tt-yellow $gradient-stripes-light;

    > :first-child {
      justify-self: start;
    }

    > :last-child {
      justify-self: end;
    }
  }

  .preview-title {
    font-size: $font-size-h3;
    text-align: center;
  }
}
