/// Components - exist as named classes

/// Stylelint: exclude rules that allow human-friendly sorting, grouping and alignment
// stylelint-disable scss/dollar-variable-colon-space-after
// stylelint-disable scss/dollar-variable-empty-line-before
// stylelint-disable scss/double-slash-comment-empty-line-before

@use "sass:math";
@use "@functions/color-contrast" as contrast;
@use "@functions/color-transparency" as transparency;
@use "@functions/units" as unit;
@use "@variables/color-palette" as *;
@use "./base" as *;
@use "./theme" as *;


/// Shared private settings

$_icon-size:                    25px;
$_icon-size-lg:                 45px;
$_icon-size-xl:                 75px;


/// Alerts

$alert-max-width:               $layout-content-max-width;
$alert-persistent-max-width:    $layout-max-width;
$alert-z-index:                 $zindex-fixed; // When dismissible

$alert-border-width:            $border-width;
$alert-border-radius:           $border-radius-md;

$alert-icon-font-size:          unit.rem($_icon-size, $font-size--px-base);

$alert-default-text:            $theme-text;
$alert-default-theme:           $theme-main;

$alert-themes: (
  "success":                    $theme-success,
  "warning":                    $theme-warning,
  "danger":                     $theme-danger,
);


/// Avatars

$avatar-size:                   40px; // user-info and comment components, total score
$avatar-size-xs:                25px; // Program card footer
$avatar-size-sm:                30px; // User menu, score by competence
$avatar-size-lg:                120px; // Profile


/// Badges

$badge-size:                    unit.rem(20px);
$badge-font-size:               $font-size-xs;

$badge-default-theme:           $theme-accent;


/// Labels

$label-size:                    unit.rem(18px);
$label-font-size:               $font-size-xs;

$label-default-theme:           $theme-accent;

$label-themes: (
  "success":                    $theme-success,  // "Joined program"
  "warning":                    $theme-warning,  // Competence motivational ("New skill!")
  // "danger":                     $theme-danger,
  "admin":                      $admin-color,           // "admin" after user name in user-info, comment
  "coach":                      $coach-color,           // "coach" after user name in user-info, comment
);


/// Buttons

// px values for local calculations
$_button-height-px:             45px;
$_button-height-touch-px:       51px;
$_button-font-size-px:          14px;

$button-font-size:              unit.rem($_button-font-size-px, $font-size--px-base);

$button-border-width:           $border-width; // Shared with .filters
$button-border-radius:          $border-radius-circle;

$button-min-width:              unit.em($_button-height-px, $_button-font-size-px);
$button-mid-width:              unit.em(200px, $_button-font-size-px);
// Wide enough for "(√) Vorbereitung wiederholen"
$button-max-width:              unit.em(280px, $_button-font-size-px);
$button-height:                 unit.em($_button-height-px, $_button-font-size-px);
$button-height-touch:           unit.em($_button-height-touch-px, $_button-font-size-px);
$button-padding-inline:         var(--tt-gap-sm);

$button-shadow:                 0 4px 1em transparency.black(12%);
$button-hover-shadow:           1px 2px 8px transparency.black(16%);
$button-no-shadow:              0 0 0 -1px $white; // -1px visually hides shadow

$button-hover-shade:            $theme-shade-fx;
$button-hover-tint:             $theme-tint-fx;
$button-active-shade:           $theme-shade-light-fx;

$button-default-theme:          $theme-text; // prop
$button-default-text:           $theme-text; // hex color
$button-default-background:     transparency.white(90%);

$button-border-default-fx:      65%;
$button-border-hover-fx:        0%;
$button-border-no-fx:           0%;

$button-themes: (
  "primary": (
    "style":                    "filled",
    "theme":                    $theme-button,
    "text":                     $theme-button-text,
    "background":               $theme-button,
  ),
  "secondary": (
    "style":                    "outlined",
    "theme":                    $theme-success,
    "text":                     $theme-success,
    "background":               $button-default-background,
  ),
  "android": (
    "style":                    "outlined",
    "theme":                    $android-color,
    "text":                     $android-color,
    "background":               $button-default-background,
  ),
  "ios": (
    "style":                    "outlined",
    "theme":                    $ios-color,
    "text":                     $ios-color,
    "background":               $button-default-background,
  ),
  "linkedin": (
    "style":                    "outlined",
    "theme":                    $linkedin-color,
    "text":                     $linkedin-color,
    "background":               $button-default-background,
  ),
);


/// Forms - input fields

$_input-font-size-px:           $font-size--px-base;
// Identical height for input+button
$_input-line-height-px:         $_button-font-size-px * $font-line-height;

$input-font-size:               unit.rem($_input-font-size-px, $font-size--px-base);
$input-line-height:             unit.strip(unit.em($_input-line-height-px, $_input-font-size-px));

$input-padding-block:           unit.em(
                                  ($_button-height-px - $border-width * 2 - $_input-line-height-px) * 0.5,
                                  $_input-font-size-px
                                );
$input-padding-inline:          var(--tt-gap-sm);
$input-border-radius:           $border-radius-md;

$input-default-border:          $border-color;

$input-themes: (
  "focus":                      $theme-main,
  "success": (
    "theme":                    $theme-success,
    // "text":                     $theme-success,
    "border":                   $theme-success-light,
  ),
  "error": (
    "theme":                    $theme-danger,
    // "text":                     $theme-danger,
    "border":                   $theme-danger-light,
  ),
);


/// Cards

// Calculate card width for n cards in a grid
$_cards-grid-cards-per-row:     4;
$_cards-grid-gap:               $gap--px-base;
$_cards-available-space:        $layout-max-width - 2*2*$_cards-grid-gap - $_cards-grid-gap * ($_cards-grid-cards-per-row - 1);

$card-min-width:                math.div($_cards-available-space, $_cards-grid-cards-per-row);
$card-max-width:                $component-max-width;
$card-border-radius:            $border-radius-lg;

// Z-indeces for clickable card and maintain accessible links
$card-zindex-hitarea:           1;
$card-zindex-inner-link:        2;
$card-zindex-flag:              3;

$card-list-icon-theme:          $theme-main;

$card-default-background:       $white;
$card-box-shadow:               $component-shadow;

$card-themes: (
  "activity": (
    "completed":                $theme-success,
    "locked":                   $theme-neutral,
    "next":                     $theme-main,
  ),
  "event": (
    "result":                   $theme-warning,
    "unread":                   $theme-danger,
  ),
  "program": (
    "completed":                $theme-success,
  ),
);


/// Choices: custom checkboxes, radio buttons and criteria

$choice-unchecked-color:        $theme-neutral;
$choice-checked-color:          $theme-button;
$choice-hover-color:            $theme-button;


/// Icons

$icon-font-size:                $font-size-sm;
$icon-font-size-lg:             unit.rem($_icon-size, $font-size--px-base);

// NEW DESIGN 2023: activity status
$circle-icon-font-size:         $font-size-sm;
$circle-icon-font-size-lg:      unit.rem($_icon-size, $font-size--px-base);
$circle-icon-size:              unit.em($_icon-size, unit.px($circle-icon-font-size, $font-size--px-base));
$circle-icon-size-lg:           unit.em($_icon-size-lg, unit.px($circle-icon-font-size-lg, $font-size--px-base));

$icon-default-theme:            $theme-neutral;
$icon-default-text:             contrast.color($theme-neutral);

$icon-themes: (
  // Completed check
  "success": (
    "theme":                    $theme-success,
    "text":                     contrast.color($theme-success),
  ),
);


/// Modals (Bootstrap 3)
// In bootstrap.scss
// $modal-content-fallback-border-color:   transparent;
// $modal-content-border-color:            transparent;
// $modal-header-border-color:             transparent;
// $modal-footer-border-color:             transparent;


/// Progress bars

$progress-height:               unit.rem(8px, $font-size--px-base);
$progress-height-lg:            $progress-height * 1.5;
$progress-border-radius:        $border-radius-xxs;
$progress-bg:                   $tt-gray-200;
$progress-default-theme:        $theme-main;


/// Scores

$score-height-px:               20px;
$score-height:                  unit.rem($score-height-px);

$score-label-height:            unit.rem(30px);
$score-label-border:            2px;

$score-scale-height:            $line-height;
$score-scale-line-width:        $border-width;
$score-scale-color:             $gray-700;
$score-scale-border-color:      $gray-200;

$score-scales: (
  "default": (
    //                          –   75  100 125
    "grid":                     1fr 2fr 2fr 1fr,
    "hide-zero":                var(--ON),
  ),
  "percent": (
    //                          0   25  50  75  100
    "grid":                     1fr 2fr 2fr 2fr 1fr,
    "hide-zero":                var(--OFF),
  ),
);

$score-default-text:            $theme-main-contrast;
$score-default-theme:           $theme-main;
$score-default-theme-to:        $theme-main;

$score-themes: (
  // E.g. norm
  "neutral": (
    "text":                     contrast.color($theme-neutral),
    "theme":                    $theme-neutral,
    "theme-to":                 $theme-neutral-light,
  ),

  // User's score = default
  // "user": (
  //   "text":                     $theme-main-contrast,
  //   "theme":                    $theme-main,
  //   "theme-to":                 $theme-main,
  // ),

  // User's score == 100%
  "full": (
    "text":                     contrast.color($theme-full),
    "theme":                    $theme-full,
    "theme-to":                 $theme-full-light,
  ),
);


/// Spinners / loaders

$spinner-size:                  $font-size-xl * 3;


/// Wallpapers

$wallpaper-max-width:           $component-max-width;

$wallpaper-icon-size-sm:        unit.rem($_icon-size, $font-size--px-base);
$wallpaper-icon-size-lg:        unit.rem($_icon-size-xl, $font-size--px-base);

$wallpaper-default-theme:       $theme-neutral;

$wallpaper-themes: (
  "muted":                      $theme-neutral,  // Inside placeholder?
  "success":                    $theme-success,  // Call status, MC answer correct
  "danger":                     $theme-danger,   // Call status, MC answer incorrect
);
