/// Navigation: navbars

// nav.navigation[.-tabs]
//   ul|ol
//     li[aria-current]
//       a
//   or:
//   a[.-active]

@use "@functions/units" as unit;
@use "../_mixins/mq" as mq;
@use "../variables" as *;

// Navigation between content views
// Program, Notifications
// Assessment result
// <nav>
.navigation {
  --link: #{$theme-text};
  --hover: #{$theme-button};

  display: flex;
  gap: var(--tt-gap-sm);
  align-items: center;
  justify-content: space-between;
  padding: var(--tt-gap-sm) var(--tt-gap);

  ul,
  ol,
  li {
    display: contents;
  }

  a {
    position: relative;
    display: inline-flex;
    gap: $inline-gap;
    align-items: center;
    line-height: unit.rem(30px, $font-size--px-base);

    &::after {
      position: absolute;
      inset: auto 0 (-$gap-xs) 0;
      content: "";
      border-bottom: 2px solid var(--link-fx);
    }

    &:hover,
    &:focus-visible {
      --link-icon: 1;
      --link-fx: currentcolor;
    }
  }

  i {
    font-size: $font-size-lg;
    opacity: var(--link-icon, 0.6);
  }

  // <li>, all navigation
  [aria-current],
  // <a>, program navigation
  .-active {
    --link: #{$theme-button};
    --link-icon: 1;
    --link-fx: currentcolor;
  }
}

/// Main navigation

// <nav>
.main-navigation {
  --link: #{$theme-header-text};
  --hover: #{$theme-header-text};

  display: contents;

  a::after {
    bottom: 0;
  }

  [aria-current] {
    --link: #{$theme-header-text};

    .default-theme & {
      --link: #{$tt-blue};
    }
  }
}

/// Program navigation: [Program - Notifications]

// Cut off the title of the program on smaller screens
.nav-program {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // When it's not a "back to program" link, a.k.a. has "back" icon
  &:not(:has(i)) {
    font-size: $font-size-h2;
  }
}

// Hide the text in notifications link on mobile, only show icon
.nav-notifications {
  i {
    font-size: $icon-font-size-lg;
  }

  .nav-text {
    @include mq.max-width-sm {
      display: none;
    }
  }
}
