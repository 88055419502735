/// PlayRecord
// .playrecord[data-react-component="PlayRecord"]
//   .playrecord
//     {screen}

@use "@functions/color-transparency" as transparency;
@use "@functions/units" as unit;
@use "../_mixins/mq" as mq;
@use "../variables" as *;

// React wrapper - Faux fullscreen
// [data-react-component="PlayRecord"]
.playrecord {
  display: contents;

  h1,
  h2,
  h3 {
    margin-block-start: 0;
  }

  /// Modal for warnings
  // Override Bootstrap hiding by default
  .modal {
    display: block;
  }
}

/// Loaders: spinner, countdown, saving, analysing, progress animation etc.
// .pr-loader
//   h2
//   .pr-loader-inner > any loader
//   p

.pr-loader {
  display: grid;
  grid-template-rows: 3rem 1fr 3rem;
  gap: $gap;
  width: $component-max-width-sm;
  height: $component-max-height-sm;
  margin-inline: auto;
  text-align: center;
}

.pr-loader-inner {
  display: grid;
  place-items: center;
}

// .spinner {}

/// Countdown to start exercise
.pr-countdown {
  $countdown-modifier: 1.5;

  display: grid;
  place-items: center;
  width: $spinner-size * $countdown-modifier;
  height: $spinner-size * $countdown-modifier;
  aspect-ratio: 1;
  font-size: $font-size-h1 * $countdown-modifier;
  font-weight: 600;
  color: $theme-main;
  background: $theme-main-contrast;
  border-radius: $border-radius-md;
  box-shadow: $component-shadow;
}

/// The exercise

// Video player (stimulus)
// .pr-player
//   .pr-video-overlay
//   video
// .pr-controls
//   .pr-progress

// Recorder
// .pr-recorder
//   .pr-webcam-preview | .pr-microphone-preview
//     video            |   i.["microphone"]
// .pr-controls
//   .pr-progress
//   .pr-teleprompter

// Multiple Choice
// .pr-multiple-choice
// .pr-controls
//   .pr-progress

// Z-indeces
// 1: .pr-player
// 2: .pr-recorder or .pr-multiple-choice
// 3: .pr-teleprompter
// 4: .pr-webcam-preview

.pr-exercise,
.pr-multiple-choice {
  position: relative; // Stacking components when recording
  display: grid;
  gap: $gap-2xl $gap;
  place-items: end center;
}

.pr-exercise {
  grid-template:
    "player camera"
    "controls controls"
    / 7fr 4fr;
}

.pr-multiple-choice {
  grid-template:
    "player"
    "controls";

  // Should not be rendered at all, but hide just to be safe
  .pr-camera,
  .pr-recorder {
    display: none;
  }
}

.pr-video {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-md;
  box-shadow: var(--pr-highlight, none);
  transition: box-shadow $animation-speed $animation-timing;

  &.-focus {
    --pr-highlight: #{0 0 3px 5px $theme-accent};
  }
}

.pr-video-overlay {
  position: absolute;
  inset: 0;
}

.pr-player {
  position: relative;
  z-index: 1;
  grid-area: player;
  max-width: $media-width;
  max-height: $media-height;
  aspect-ratio: 16 / 9;

  // MC videos answer
  &.-small {
    max-width: $media-width * 0.5;
    margin: auto;
  }

  // &.-playing {}

  &.-recording {
    filter: brightness(0.8);
  }
}

.pr-recorder {
  position: relative;
  z-index: 2;
  grid-area: player;
  place-self: stretch;
  overflow: hidden;
}

.pr-recording-alert {
  position: absolute;
  inset: 0 0 auto;
  z-index: 1;
  transform: translateY(-100%);
}

/// Camera while recording

.pr-camera {
  position: relative;
  display: grid;
  grid-area: camera;
  place-items: center;
  width: 100%;
  max-height: $component-max-height-sm;
  aspect-ratio: 4 / 3; // If not, collapses and won't show show/hide button and audio-only image
  color: $gray-200;
  background: radial-gradient(at bottom, $gray-700, $media-bg);

  video {
    transform: scaleX(-1);
  }
}

// Big icon for audio-only and hidden camera
.pr-camera-icon {
  position: absolute;
  font-size: 4em;
  opacity: 0.4;
}

// = .button.-circle
.pr-toggle-camera {
  position: absolute;
  bottom: $gap-sm;
  background: transparency.black(60%);

  &.-on {
    --THEME: #{$gray-400};
  }

  &.-off {
    --THEME: #{$theme-danger};
  }
}

/// Control buttons

.pr-controls {
  position: relative;
  display: grid;
  grid-area: controls;
  grid-template:
    "progress progress progress"
    "rew playpause fwd"
    / 1fr auto 1fr;
  gap: $gap;
  place-items: center;
  width: min(100%, $media-width);

  @include mq.min-width-sm {
    grid-template-columns: 1fr min(100%, $button-max-width) 1fr;
  }
}

// Play/pause, stop recording, replay video
.pr-control-playpause,
.pr-control-record {
  grid-area: playpause;
}

.pr-control-record {
  // Blinking dot inside recording button
  i {
    color: $theme-danger !important;
    animation: blink 1.5s step-start 0s infinite;
  }
}

// Restart & skip video
.pr-control-rewind,
.pr-control-forward {
  --btn-brd: transparent;
}

.pr-control-rewind {
  grid-area: rew;
  margin-left: auto;
}

.pr-control-forward {
  grid-area: fwd;
  margin-right: auto;
}

// Text inside buttons
.pr-control-text {
  display: none;

  @include mq.min-width-sm {
    display: initial;
  }
}

// Progress bar when
// 1. playing or recording media
// 2. uploading recording
// .pr-progress
//   <span>
//   <progress>
//   <span>
.pr-progress {
  display: grid;
  gap: $gap-sm;
  place-items: center;
  width: 100%;
  margin: $gap 0;
  font-size: $font-size-sm;
  line-height: 1;

  &:has(:not(:only-child)) {
    grid-template-columns: 4ch 1fr 4ch;
  }

  // currenttime
  // <span>
  :first-child {
    text-align: right;
  }

  // duration
  // <span>
  :last-child {
    text-align: left;
  }

  .pr-controls & {
    grid-area: progress;
  }
}

/// Teleprompter

// Display/write custom script
.pr-teleprompter-setup {
  position: relative;

  textarea {
    display: block;
    height: 12em;
    color: $white;
    resize: vertical;
    background: $gray-900;

    &:focus {
      color: $black;
      background: $white;
    }
  }

  [class*="pencil"] {
    position: absolute;
    right: 0.75rem;
    bottom: 1rem;
    color: $white;
    pointer-events: none;
  }
}

// Teleprompter during recording
.pr-teleprompter {
  position: absolute;
  inset: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  overflow: hidden;
  color: $white;
  background: transparency.black(80%);
  animation: fade-in 180ms ease-in;
}

// The element that scrolls
.pr-teleprompter__scroll {
  position: absolute;
  top: 100%;
  transform: translateY(calc(-1px * var(--pr-tp-scroll)));
}

.pr-teleprompter__osd {
  position: absolute;
  top: $gap;
  right: $gap;
  display: grid;
  gap: $gap-sm;
  font-size: 1rem;
  color: $white;
  opacity: var(--pr-tp-fade-op);
  transition: opacity var(--pr-tp-fade-ani);

  &.-hide {
    --pr-tp-fade-op: 0;
    --pr-tp-fade-ani: #{$animation-speed-slow} ease-out;
  }

  &.-show {
    --pr-tp-fade-op: 1;
    --pr-tp-fade-ani: #{$animation-speed-fast} ease-in;
  }
}

// <textarea|div>
.pr-teleprompter-script {
  width: min(450px, 100%);
  padding: $gap;
  margin-inline: auto;
  font-family: $font-family-default;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

/// Setup hardware & error screens
$_computed-header-height: $heading-line-height-lg * $font-size--px-base;
$_computed-button-height: 45px; // $button-height;
$computed-menu-offset-y: ($_computed-header-height - $_computed-button-height) * 0.5;

.pr-menu {
  --THEME: #{$theme-neutral};

  position: fixed;
  top: var(--tt-gap);
  right: var(--tt-gap);
  z-index: $zindex-fixed + 1; // Place over any PR content and navbars
  display: flex;
  transform: translateY($computed-menu-offset-y);
}

.pr-setup {
  display: flex;
  flex-direction: column;
  gap: $gap-lg;
  align-items: center;
  justify-content: center;
  min-height: $component-max-height-sm;

  // <i>
  [class*="microphone"] {
    font-size: 5em;
  }

  // Hardware permission chrome example
  img {
    border: $border-width dashed $border-color;
    border-radius: $border-radius;
  }

  video {
    transform: scaleX(-1);
  }
}

// Setup complete
.pr-setup__icon-stack {
  position: relative;
  text-align: center;

  [class*="laptop"] {
    font-size: 8em;
  }

  [class*="check"] {
    position: absolute;
    top: 1.2em; // magic nr
    left: 50%;
    font-size: 3em;
    color: $theme-success;
    transform: translate(-50%, -50%);
  }
}

// Microphone volume level
.pr-volume {
  position: relative;
  width: 100%;
  height: unit.rem(24px);
  overflow: hidden;
  background: $gray-700;
  border-radius: $border-radius-md;
}

.pr-volume__bar {
  position: absolute;
  inset: 0 auto 0 0;
  width: clamp(1%, var(--w), 100%);
  background: $theme-success linear-gradient(to right, $theme-success, $theme-success-light);
}
