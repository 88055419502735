/// Menus

@use "../variables" as *;

// BS: .dropdown
:where(.help-menu, .environment-menu, .user-menu) {
  // display: contents;
  display: inherit;

  // BS: .dropdown-toggle
  > a {
    display: inline-flex;
    align-items: center;

    // `first` excludes trailing dropdown caret
    i:first-child {
      font-size: $font-size-xl;
    }
  }
}

// Help center, chat, about, etc.
// .help-menu {}

// Switch user role environment
// .environment-menu {}

// Profile, settings, language, sign out
// Quit to LMS: [data-session="lms"]
.user-menu {
  .locale {
    font-size: $font-size-sm;
  }
}
