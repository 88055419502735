/// Result of an assessment: total score passed the norm or not

@use "../variables" as *;

// <h2>
.result-title {
  &.-passed {
    --icon: #{$tt-gold};
  }

  &.-failed {
    --icon: #{$theme-neutral};
  }
}
