/// Helpers or Utilities

@mixin accessibility-focus($color: dodgerblue) {
  outline: 3px solid $color;
  outline-offset: 2px;
}

// https://css-tricks.com/inclusively-hidden/
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}
