/// Search friends
// .search
//   input[type="search"].form-control
//   button[type="button"].button
//     i.ph

@use "@functions/units" as unit;
@use "../variables" as *;

// Identical to .filter
.search {
  width: min(100%, $component-max-width);
  margin-inline: auto;
}

// Similar to .filter-bar
.search-bar {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;

  input {
    padding-inline-start: unit.rem(52px); // $button-height
  }

  button {
    --btn-bg: none;
    --btn-shadow: none;

    position: absolute;
    inset: $border-width auto $border-width $border-width;
  }
}
