/// Progress bars
// - Loading & saving
// - Playing & recording in PlayRecord
// - Progress on program cards

@use "@functions/color-transparency" as transparency;
@use "../variables" as *;

// Animate ltr
@keyframes progress-bar {
  from {
    background-position: 0 0;
  }

  to {
    background-position: $progress-animation-size;
  }
}

// <progress>
progress {
  display: block;
  width: 100%; // auto?
  height: $progress-height;
  overflow: hidden;
  color: $theme-text;
  appearance: none;
  background-color: transparency.alpha($gray-200, 84%);
  border: 0; // For Firefox
  border-radius: $progress-border-radius;
  box-shadow: inset 0 1px 2px transparency.black(8%);

  /// Browsers chrome

  &::-moz-progress-bar {
    background-color: var(--progress-theme, $progress-default-theme);
    background-image: $gradient-glossy;
  }

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: var(--progress-theme, $progress-default-theme);
    background-image: $gradient-glossy;
  }

  /// Variations

  &.-stripes {
    &::-moz-progress-bar {
      background-image: $gradient-stripes-light, $gradient-glossy;
    }

    &::-webkit-progress-value {
      background-image: $gradient-stripes-light, $gradient-glossy;
    }
  }

  // E.g. saving answer in PlayRecord
  // Combine with .-stripes
  &.-animate {
    animation: $progress-animation;

    &::-moz-progress-bar {
      background-position: inherit;
      background-size: $progress-animation-size;
    }

    &::-webkit-progress-inner-element {
      background-position: inherit;
    }

    &::-webkit-progress-bar {
      background-position: inherit;
    }

    &::-webkit-progress-value {
      background-position: inherit;
      background-size: $progress-animation-size;
    }
  }

  /// Themes

  &.-success {
    --progress-theme: #{$theme-success};
  }

  &.-warning {
    --progress-theme: #{$theme-warning};
  }

  &.-danger {
    --progress-theme: #{$theme-danger};
  }
}
