/// QR code to sign in directly in the mobile app

$_qr-pixel-size: 3px;

.qrcode-wrapper {
  display: flex;
  justify-content: center;
  padding-block: var(--tt-gap-lg);
}

.qrcode {
  margin-block-end: calc(4 * var(--tt-gap-lg));

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  td {
    width: $_qr-pixel-size;
    height: $_qr-pixel-size;
    padding: 0;

    &.black {
      background-color: black;
    }

    &.white {
      background-color: white;
    }
  }
}
