/// Color palette

/// Exclude a handfull rules for variables sheet because it allows improved sorting and grouping
// stylelint-disable scss/dollar-variable-colon-space-after
// stylelint-disable scss/dollar-variable-empty-line-before
// stylelint-disable scss/double-slash-comment-empty-line-before


/// Grays
// @TODO pick one

/// Gray: Pale Gray
// (Mostly) backgrounds
// $pale-gray-100:                 hsl(0deg 0% 100%);      // #fff
// $pale-gray-200:                 hsl(180deg 100% 100%);  // #feffff
// $pale-gray-300:                 hsl(210deg 100% 100%);  // #fdfeff
// $pale-gray-400:                 hsl(210deg 100% 100%);  // #fdfeff // identical to 300?
// $pale-gray-500:                 hsl(220deg 100% 99%);   // #fcfdff
// $pale-gray-600:                 hsl(210deg 100% 99%);   // #fbfdff
// $pale-gray-700:                 hsl(220deg 3% 79%);     // #c9cacc
// $pale-gray-800:                 hsl(210deg 1% 60%);     // #979899
// $pale-gray-900:                 hsl(210deg 1% 40%);     // #646566
// $pale-gray-1000:                hsl(180deg 1% 20%);     // #323333
$tt-gray-50:                    hsl(210deg 100% 99%);   // #fbfdff // = pale-gray-600
$tt-gray-100:                   hsl(210deg 17% 98%);    // #f8f9fa
$tt-gray-200:                   hsl(204deg 17% 94%);    // #eef1f3 // Copy-pasted from Figma design
$tt-gray-300:                   hsl(204deg 17% 90%);    // #e1e6ea // Derived from $tt-gray-200

// Grays: hue of Suit Blue (see below)
$white:                         #fff;
$gray-100:                      hsl(210deg 18% 98%);    // #f8f9fa
$gray-150:                      hsl(210deg 18% 92%);    // #e1e6ea
// Too big of a step between 100-200
$gray-200:                      hsl(210deg 12% 84%);    // #d1d6db
$gray-300:                      hsl(210deg 12% 72%);    // #afb8c0
$gray-400:                      hsl(210deg 12% 64%);    // #98a3ae
$gray-500:                      hsl(210deg 12% 56%);    // #818f9c
$gray-600:                      hsl(210deg 12% 48%);    // #6c7a89
$gray-700:                      hsl(210deg 12% 32%);    // #48525b
$gray-800:                      hsl(210deg 12% 24%);    // #363d45
$gray-900:                      hsl(210deg 12% 16%);    // #24292e
$black:                         #000;


/// TrainTool color palette

// Blue "Secret of Mana"
// $tt-blue-50:                    hsl(226deg 100% 99%);   // #f9faff
// $tt-blue-100:                   hsl(226deg 90% 96%);    // #ecf0fe
// $tt-blue-200:                   hsl(226deg 90% 92%);    // #d9e1fd
// $tt-blue-300:                   hsl(226deg 88% 84%);    // #b3c3fa
$tt-blue-400:                   hsl(226deg 89% 76%);    // #8ca5f8
// $tt-blue-500:                   hsl(226deg 88% 68%);    // #6687f5
$tt-blue-600:                   hsl(226deg 88% 60%);    // #4069f3
// $tt-blue-700:                   hsl(226deg 58% 48%);    // #3354c2
$tt-blue-800:                   hsl(226deg 59% 36%);    // #263f92
// $tt-blue-900:                   hsl(226deg 58% 24%);    // #1a2a61
// $tt-blue-1000:                  hsl(226deg 58% 12%);    // #0d1531

// Dark blue-gray "Suit Blue"
// $tt-darkblue-100:               hsl(210deg 2% 92%);     // #eaebeb
// $tt-darkblue-200:               hsl(210deg 2% 84%);     // #d5d6d7
$tt-darkblue-300:               hsl(210deg 2% 68%);     // #abadaf
$tt-darkblue-400:               hsl(210deg 3% 52%);     // #808488
// $tt-darkblue-500:               hsl(210deg 5% 36%);     // #565b60
$tt-darkblue-600:               hsl(210deg 12% 20%);    // #2c3238
$tt-darkblue-700:               hsl(210deg 13% 16%);    // #23282d
// $tt-darkblue-800:               hsl(210deg 13% 12%);    // #1a1e22
// $tt-darkblue-900:               hsl(210deg 10% 8%);     // #121416
// $tt-darkblue-1000:              hsl(210deg 10% 4%);     // #090a0b

// Green "Cool Grass"
// $tt-green-100:                  hsl(158deg 42% 94%);    // #e9f6f1
// $tt-green-200:                  hsl(158deg 45% 88%);    // #d2eee4
// $tt-green-300:                  hsl(158deg 44% 76%);    // #a6dcc9
$tt-green-400:                  hsl(158deg 44% 64%);    // #79cbad
// $tt-green-500:                  hsl(158deg 44% 51%);    // #4db992
$tt-green-600:                  hsl(158deg 68% 39%);    // #20a877
// $tt-green-700:                  hsl(158deg 68% 31%);    // #1a865f
// $tt-green-800:                  hsl(158deg 68% 24%);    // #136547
// $tt-green-900:                  hsl(158deg 68% 16%);    // #0d4330
// $tt-green-1000:                 hsl(158deg 70% 8%);     // #062218

// Yellow custom, no name
$tt-yellow-400:                 hsl(40deg 100% 76%);    // #ffd184
$tt-yellow-600:                 hsl(40deg 100% 63%);    // #ffbf43

// Orange "Absolute Apricot"
// $tt-orange-100:                 hsl(25deg 100% 96%);    // #fff4ec
// $tt-orange-200:                 hsl(25deg 100% 93%);    // #ffe9d9 // alert bg
// $tt-orange-300:                 hsl(25deg 100% 85%);    // #ffd3b4
$tt-orange-400:                 hsl(25deg 100% 78%);    // #ffbe8e
// $tt-orange-500:                 hsl(25deg 100% 71%);    // #ffa869
$tt-orange-600:                 hsl(25deg 100% 63%);    // #ff9243
// $tt-orange-700:                 hsl(25deg 60% 51%);     // #cc7536
// $tt-orange-800:                 hsl(25deg 59% 38%);     // #995828 // alert text
// $tt-orange-900:                 hsl(25deg 58% 25%);     // #663a1b
// $tt-orange-1000:                hsl(25deg 59% 13%);     // #331d0d

// Red "Tomato Frog"
// $tt-red-100:                    hsl(0deg 100% 96%);     // #ffecec
// $tt-red-200:                    hsl(0deg 100% 93%);     // #ffd9d9
// $tt-red-300:                    hsl(0deg 100% 85%);     // #ffb4b4
$tt-red-400:                    hsl(0deg 100% 78%);     // #ff8e8e
// $tt-red-500:                    hsl(0deg 100% 71%);     // #ff6969
$tt-red-600:                    hsl(0deg 100% 63%);     // #ff4343
// $tt-red-700:                    hsl(0deg 60% 51%);      // #cc3636
// $tt-red-800:                    hsl(0deg 59% 38%);      // #992828
// $tt-red-900:                    hsl(0deg 58% 25%);      // #661b1b
// $tt-red-1000:                   hsl(0deg 59% 13%);      // #330d0d


/// TrainTool color scheme

// TrainTool logo
$tt-blue:                       #4069f3;              // Speech bubble
$tt-gray:                       #646373;              // Title and pay-off

$tt-green:                      $tt-green-600;
$tt-green-light:                $tt-green-400;

$tt-yellow:                     $tt-yellow-600;
// $tt-yellow-light:               $tt-yellow-400;

$tt-orange:                     $tt-orange-600;
$tt-orange-light:               $tt-orange-400;

$tt-red:                        $tt-red-600;
$tt-red-light:                  $tt-red-400;

// Trainee scores, results, icons
$tt-gold:                       hsl(51deg 100% 50%);  // = CSS name `gold`
$tt-gold-light:                 hsl(51deg 100% 70%);


/// Specific themes

// User types: buttons, labels
$superuser-color:               $tt-red;
$admin-color:                   $tt-orange;
$coach-color:                   $tt-yellow;
$trainee-color:                 $tt-blue;

// Segment types: headings
$practice-color:                $tt-blue-600;
$live-color:                    $tt-green;
$assessment-color:              $tt-yellow;

// Help/info: (?) links and (i) texts
$help-color:                    $gray-600;
$info-color:                    $gray-600;

// Avatar backgrounds
$avatar-alix:                   $tt-blue;       // Coach is Alix
$avatar-0:                      skyblue;        // No initial: removed user
$avatar-1:                      limegreen;
$avatar-2:                      chocolate;
$avatar-3:                      slateblue;
$avatar-4:                      salmon;
$avatar-5:                      orchid;
$avatar-6:                      lightseagreen;
$avatar-7:                      goldenrod;
$avatar-8:                      forestgreen;


/// Brands

$android-color:                 #6ab344;
$ios-color:                     #323232;
$linkedin-color:                #2977c9;


/// YIQ contrast colors
// E.g. labels: text on coloured background
// Threshold: when to switch from light to dark or dark to light based on given color
// Value between 0 and 255, default = 128
$yiq-threshold:                 148;
$yiq-dark:                      $gray-900;
$yiq-light:                     $white;
