/// Leave exercise or preparation

@use "../variables" as *;

$_computed-header-height: $heading-line-height-lg * $font-size--px-base;
$_computed-button-height: 45px; // $button-height;
$computed-menu-offset-y: ($_computed-header-height - $_computed-button-height) * 0.5;

.exercise-exit {
  position: fixed;
  top: var(--tt-gap);
  left: var(--tt-gap);
  transform: translateY($computed-menu-offset-y);
}
