/// Web sign in page

@use "@functions/color-transparency" as transparency;
@use "../variables" as *;

// body.sign-in[.default-theme|.branding-theme]
.sign-in {
  background-color: $theme-main;
  background-image: // prettier-force-new-line
    // top-right
    linear-gradient(225deg, transparency.white(16%), transparency.white(0) 66%),
    // bottom-center
    linear-gradient(000deg, transparency.black(32%), transparency.black(0) 66%);

  .web-header {
    background: transparent none;
    box-shadow: none;
  }

  &.default-theme {
    .web-header {
      --link: #{$white};
      --hover: #{$white};

      [rel="home"] {
        fill: $white;
      }
    }
  }

  .card {
    --card-title: #{$font-size-h2};

    box-shadow: $overlay-shadow;

    // Account logo
    img {
      max-height: 4rem; // Logo's should be uploaded with height 128px = 64px@2x
    }
  }

  .card__header,
  .card__body,
  .card__footer {
    padding-inline: var(--tt-gap);
  }

  .card__header {
    justify-content: center;

    h1 {
      flex: none;
      font-size: $font-size-h2;
    }
  }
}
