/// User profile form

@use "../_mixins/mq" as mq;

// .profile {}

.profile__avatar {
  &,
  * {
    margin-inline: auto;
    text-align: center;
  }
}

@include mq.min-width-xs {
  .profile {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: var(--tt-gap);
  }

  .profile__label {
    text-align: right;
  }
}
