/// Placeholders

@use "@functions/color-transparency" as transparency;
@use "../variables" as *;

@keyframes swoosh {
  0% {
    left: 0;
  }

  33% {
    left: 125%;
  }

  100% {
    left: 125%;
  }
}

.placeholder {
  padding: $gap;
  color: $theme-neutral;
  background: transparent;
  border-radius: $border-radius;

  p {
    margin: 0;
  }
}

.placeholder-text {
  font-style: italic;
  color: $theme-neutral;

  &::after {
    content: "…";
  }
}

// When loading coach call data
.placeloader {
  position: relative;
  overflow: hidden;
  font-style: italic;
  color: $theme-neutral;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 110%;
    content: "";
    background-color: transparency.white(30%);
    transform: skew(-12deg);
    animation: swoosh 1.5s $animation-timing infinite;
  }
}
