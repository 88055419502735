// Meta data of a RolePlay in roleplay>show, not in .activity!
// @TODO: ONLY in call_details.html.haml!

@use "../variables" as *;

// <ul>
.exercise-meta {
  display: flex;
  flex-direction: column;
  gap: $gap;
  padding: 0;
  margin: var(--tt-gap) 0;
  list-style: none;
}

// <span>
.meta-label {
  --icon: var(--label-theme);

  display: flex;
  gap: $inline-gap;
  align-items: baseline;
  color: var(--label-theme, $theme-text);

  // Exercise completed (program overview and roleplay_enrollment>show)
  // Call completed (call_segment>show)
  &.-completed {
    --label-theme: #{$theme-success};
  }

  // Call canceled
  &.-canceled {
    --label-theme: #{$theme-warning};
  }

  // Exercise deadline expired
  &.-expired {
    --label-theme: #{$theme-danger};
  }
}
