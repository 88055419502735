/// Locale flags
// http://flag-icon-css.lip.is

@use "../variables" as *;

// .locale {}

.locale-flag {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-inline-end: $inline-gap;
  background: center / contain no-repeat;

  @each $ln in ("de", "en", "es", "fr", "it", "nl") {
    &.-#{$ln} {
      background-image: url("../../icons/flags/#{$ln}.svg");
    }
  }
}
