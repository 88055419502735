/// Activities within a segment in program overview

// @markup
// ol.activities
//   li.activity(.card)
//     (card content)
//     .activity-icon

@use "@functions/color-transparency" as transparency;
@use "@functions/map" as map;
@use "@functions/units" as unit;
@use "../variables" as *;

$_activity-completed-theme: map.deep-get($card-themes, "activity", "completed");
$_activity-locked-theme: map.deep-get($card-themes, "activity", "locked");
$_activity-next-theme: map.deep-get($card-themes, "activity", "next");

// Containing all .activity cards
// <ol>
.activities {
  // Grid exists of half of this width (= arbitrary number), minus 1x gap
  // - Exercise activities span 2 columns, a.k.a. "1 card width"
  // - Information activities span 3 (introduction, conclusion, live, calls), a.k.a. "1.5 card width"
  $_activity-min-width: unit.rem(192px) - $gap;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($_activity-min-width * 0.5, 1fr));
  gap: $gap;
  padding: $gap 0;
  margin: 0;
  list-style: none;

  > li {
    grid-column: span 2;

    // &.segment_information_enrollment => also applies to subsegments!
    // &.segment_call_enrollment
    &.-large {
      grid-column: span 3;
    }
  }
}

// <li>
// @extend .card
.activity {
  --THEME: var(--OFF);
  --card-theme: var(--THEME);
  --heading: var(--card-theme);
  // --heading: var(--card-theme);
  // --icon: var(--card-theme);
  // --icon-lg: var(--card-theme);

  .card__poster {
    position: relative; // For .activity-icon
    opacity: var(--card-fx-opacity, 1);
  }

  .card__header {
    h3 {
      text-align: center;
    }
  }

  /// Variations

  // Center the title on exercise activities
  // &.-small {}

  // Strip card look on large activities (Introduction etc.)
  // .segment_information_enrollment
  // .segment_call_enrollment
  // &.-large {
  //   // !important to override :hover as well
  //   --card-title: #{$font-size};
  //   --card-bg: transparent !important;
  //   --card-shadow: none !important;

  //   .card__poster {
  //     border-radius: $border-radius-md;
  //     box-shadow: $media-shadow;
  //   }

  //   .card__header,
  //   .card__body,
  //   .card__footer {
  //     padding-inline: 0;
  //   }
  // }

  /// States (themes) - cascading order matters: importance of state!

  &.-next {
    --THEME: #{$_activity-next-theme};
    // --card-theme: #{$_activity-next-theme};
    --card-shadow: 0 0 0 #{$border-width} var(--card-theme), #{$component-active-shadow};
    // --label-theme: var(--card-theme);

    font-weight: 600;
  }

  &:where(.-locked, .-completed) {
    --card-fx-opacity: 0.8;

    img {
      filter: grayscale(var(--card-fx-grayscale, 0));
    }
  }

  &.-locked {
    --THEME: #{$_activity-locked-theme};
    // --card-theme: #{$_activity-locked-theme};
    --card-fx-grayscale: 1;
  }

  &.-completed {
    --THEME: #{$_activity-completed-theme};
    // --icon-lg: #{$_activity-completed-theme};
    // --heading: #{$theme-text};
    --card-fx-grayscale: 0.2;
  }

  /// User interaction

  // data-href as fallback for Firefox https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
  &[data-href],
  &:has(> a) {
    &:hover,
    &:focus-within {
      --card-fx-opacity: 1;
      --card-fx-grayscale: 0;
    }
  }
}

// @extend .circle-icon
.activity-icon {
  position: absolute;
  box-shadow:
    0 0 0 3px transparency.white(50%),
    0 5px 25px transparency.black(50%);
}
