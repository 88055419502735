/// Badges

@use "../variables" as *;

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: $badge-size;
  height: $badge-size;
  padding: 0 $inline-gap;
  font-size: $badge-font-size;
  font-weight: 600; // Independent of parent
  line-height: 1; // Independent of parent
  color: var(--badge-theme, $badge-default-theme);
  white-space: nowrap;
  vertical-align: middle;
  background: var(--badge-theme, $badge-default-theme) $theme-tint-fx;
  border-radius: $border-radius-circle;

  /// Variants

  &.-large {
    min-width: $badge-size * 1.5;
    height: $badge-size * 1.5;
    font-size: $font-size;
    font-weight: normal;
  }

  /// Themes

  // When user is also coach: unhandled count in environment-menu
  &.-coach {
    --badge-theme: #{$coach-color};
  }

  &.-peer {
    --badge-theme: #{$trainee-color};
  }
}
