/// Help, info and error messages
// a.help       (?) Link to help, e.g. help center article
// span.info    (i) One-liner explaining context
// span.error   (!) Message on invalid input

@use "../variables" as *;

// <a>
.help,
// <span>
.info,
// <span>
.error {
  display: inline-flex;
  gap: $inline-gap;
  align-items: center; // why was it baseline?
  color: var(--help-theme, $theme-button);

  // i {
  //   color: var(--help-theme, $theme-accent);
  // }
}

.error {
  --help-theme: #{$theme-danger};
}
