/// Images

@use "../variables" as *;

// figure:has(img) => 13-12-22 not supported by Firefox yet https://caniuse.com/css-has
// figure.image > img
.image {
  // display: flex;
  // justify-content: center;
  // max-width: $media-width;
  // max-height: $media-max-height;
  // aspect-ratio: 16 / 9;
  // background: $media-bg;
  // box-shadow: $media-shadow;
  overflow: hidden;
  border-radius: $border-radius-md;
}

/// Video screenshots
// Program stimulus, exercise, user response etc.

// <img>
.thumb {
  max-width: 112px; // 16
  max-height: 63px; // 9
  border-radius: $border-radius;
}

// <img>
// .poster {}

// Account/client logos
// <a|div>
.logo {
  img {
    // Uploaded logos should be 128px max, so @2x-ready
    max-height: $logo-max-height;
  }
}

// TrainTool logo
// <a>
.tt-logo {
  svg {
    width: 160px;
    height: 48px;
    // fill: $tt-gray;

    // The speech bubble part of the logo
    // [d^="M21.6524"] {
    //   fill: $tt-blue;
    // }
  }
}
