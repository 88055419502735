/// Competence scores
// .scores[.-default|.-percent]
//   .score[.-user|.-neutral|.-full]    {1,3}
//     span.score__label
//     .score__bar
//       span.score__value
//   .score__scale
//     span.score__scale-value

@use "sass:map";
@use "../variables" as *;

$_score-label-offset: 4px;
$_score-value-width: 20px; // Computed

@keyframes score-bar {
  0% {
    width: 0;
    min-width: 0;
  }

  67% {
    width: 0;
  }

  100% {
    width: var(--score-bar-width, auto);
  }
}

// Containing all the scores and scale
.scores {
  --score-values: var(--ON);

  // To display the score scale (z-index: -1)
  position: relative;
  z-index: 0;

  // -
  display: grid;
  gap: $gap-lg;

  // Create space for .score__label, including avatar
  padding-block-start: ($avatar-size + $score-label-border - $score-height-px) * 0.5;
  padding-inline-start: $_score-label-offset;

  // Prevent scale's vertical lines from bleading outside of scores component
  overflow: hidden;

  // Hiding values by default
  color: transparent;

  /// Variations

  // Score display variations
  @each $name, $scale in $score-scales {
    &.-#{$name} {
      --scale-grid: #{map.get($scale, "grid")};
      --scale-hide-zero: #{map.get($scale, "hide-zero")};
    }
  }

  &.-hide-values {
    --score-values: var(--OFF);
  }
}

.score {
  --score-bar-width: auto;
  --score-text: var(--score-values, #{$score-default-text});
  --score-theme: #{$score-default-theme};
  --score-theme-to: #{$score-default-theme-to};

  position: relative;
  display: flex;
  align-items: center;
  line-height: $score-height;
  color: var(--score-text, $score-default-text);

  /// Themes

  @each $name, $colors in $score-themes {
    &.-#{$name} {
      --score-text: var(--score-values, #{map.get($colors, "text")});
      --score-theme: #{map.get($colors, "theme")};
      --score-theme-to: #{map.get($colors, "theme-to")};
    }
  }

  /// Variations

  // Glow the bar when user's score == 100%
  &.-full {
    --score-bar-shadow: 0 0 7px var(--score-theme-to), #{$component-shadow};
  }

  // Animate filling up the bar
  &.-animate {
    --score-bar-width: 0;
    --score-bar-animation: score-bar #{$animation-speed-slow + $animation-speed} #{$animation-timing};

    // Jiggle the whole bar when user's score == 100%
    &.-full {
      animation: jello $animation-speed-slow ease;
    }
  }
}

// Label/title of the score, floating left over the bar
.score__label {
  position: absolute;
  left: -$_score-label-offset; // Overlapping and hiding the start of the bar
  z-index: 1; // Display over bar
  display: flex;
  gap: $inline-gap;
  align-items: center;
  min-height: $score-label-height;
  padding-inline: var(--label-pad, $inline-gap-lg);
  font-size: $font-size-sm;
  color: $score-default-text;
  background-color: var(--score-theme);
  border: $score-label-border solid $white;
  border-radius: $border-radius-circle;

  // Class as fallback for Firefox https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
  &:has(.avatar),
  &.-has-avatar {
    --label-pad: 0;
  }
}

// The bar
.score__bar {
  position: relative;
  width: var(--score-bar-width, auto);

  // Only an issue with combination values in percent and 0 score
  min-width: $avatar-size - $score-label-border + $_score-value-width;

  // prettier-force-new-line
  height: $score-height;
  background: // prettier-force-new-line
    var(--score-theme, $theme-main) // prettier-force-new-line
    linear-gradient(to right, var(--score-theme-to), var(--score-theme));
  border-radius: $progress-border-radius;
  box-shadow: var(--score-bar-shadow, $component-shadow);
  animation: var(--score-bar-animation, none);
}

// Value of the score, floating right over the bar
.score__bar-value {
  position: absolute;
  right: 0;
  padding-inline: $inline-gap;
}

// Scale below the score bar
.score__scale {
  position: relative;
  display: grid;
  grid-template-columns: var(--scale-grid, auto);
  padding-top: $score-scale-height * 0.5;
  color: var(--score-values, $score-scale-color);
  border-top: $score-scale-line-width solid $score-scale-border-color;
}

// [–|0] (25 50) 75 100 125
.score__scale-value {
  position: relative;
  text-align: center;

  // Grid lines
  &::before {
    position: absolute;
    bottom: $score-scale-height;
    left: 50%;
    z-index: -1; // Show below score bar
    height: 12rem; // 🧙‍♂️✨ Magic nr, ~ computed max height of .scores with all 3 bars present
    content: "";
    border-left: $score-scale-line-width solid $score-scale-border-color;
  }

  &:first-child {
    color: var(--scale-hide-zero, transparent);
    text-align: start;

    &::before {
      left: 0;
      display: var(--scale-hide-zero, none);
    }
  }

  &:last-child {
    text-align: end;

    &::before {
      left: calc(100% - $score-scale-line-width);
    }
  }
}
