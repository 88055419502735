/// All the basics: reset, plain html elements etc.
// Big thanks to https://piccalil.li/blog/a-modern-css-reset/

@use "../variables" as *;

/// Reset the box-model

*,
*::before,
*::after {
  box-sizing: border-box;
}

/// Document

// :root {}

html {
  font-family: $font-family;
  font-size: $font-size-root; // clamp(12px, 0.8rem + 0.2vw, 18px)
  line-height: $font-line-height;
  color: $theme-text;
  text-rendering: optimizeLegibility; // stylelint-disable-line value-keyword-case
  background: $theme-body; // Necessary to override iframe styles (LMS: SCORM)?
  -ms-text-size-adjust: 100%; // stylelint-disable-line property-no-vendor-prefix
  -webkit-text-size-adjust: 100%; // stylelint-disable-line property-no-vendor-prefix
}

body {
  margin: 0;
}

/// Links

a {
  // Private property, do not use elsewhere for inheritance => use --link!
  --a: var(--link, #{$theme-link});

  color: var(--a);
  text-decoration: none;
  text-decoration-skip-ink: auto;

  // cursor: pointer; // Not all <a> might have href attribute

  :where(h1, h2, h3, h4, h5, h6) & {
    --a: inherit;
  }

  p & {
    color: inherit;
    text-decoration: underline;
  }

  &:hover,
  &:focus-visible {
    --a: var(--hover);
  }

  // &:focus {
  //   @include helpers.accessibility-focus;
  //   outline: 5px auto -webkit-focus-ring-color;
  //   outline-offset: -2px;
  // }
}

/// Horizontal rules

hr {
  margin: $line-height-sm 0;
  border: 0;
  border-top: 1px solid $border-color;
}

/// Media and objects

figure {
  margin: 0;
}

img,
svg {
  display: inline-block; // Not block: allows text-align:center on parent (profile edit>avatar)
  max-width: 100%;
  vertical-align: middle;
  object-fit: contain;
}

// All 100% to satisfy all browsers
video {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background: $media-bg;
}

/// Tables

table {
  width: 100%;
  border-collapse: collapse;
}

/// Hidden attribute
// Always hide an element with the `hidden` HTML attribute
// https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/hidden

[hidden] {
  display: none !important;
}

/// Accessibility, or Let's Be Nice to People™
// https://github.com/jensimmons/cssremedy/issues/11#issuecomment-462867630

// @media (prefers-reduced-motion: no-preference) {
//   :root {
//     scroll-behavior: smooth;
//   }
// }

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}
