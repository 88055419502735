/// Spinner (loader)

@use "../variables" as *;

.spinner {
  width: $spinner-size;
  height: $spinner-size;
  margin: auto;
  border: 3px solid $border-color;
  border-top-color: $theme-main;
  border-radius: $border-radius-circle;
  animation: spin $animation-speed-xslow $animation-timing infinite;
}
