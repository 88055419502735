/// Sass variables for theme
// Determines whether to use customizable properties (var())
// or fixed, non-customizable colors (#hex)

// Exclude Stylelint rules that allow human-friendly sorting, grouping and alignment
// stylelint-disable scss/dollar-variable-colon-space-after
// stylelint-disable scss/dollar-variable-empty-line-before
// stylelint-disable scss/double-slash-comment-empty-line-before

@use "@functions/color-contrast" as contrast;
@use "@functions/color-transparency" as transparency;
@use "@variables/color-palette" as *;


/// Branding colors

// List used to generate the theme as CSS Custom Properties
$branding: (
  // Sign-in, web and app header, menu and backgrounds
  // Component themes: alerts, icons, wallpapers, segments, activities etc.
  "main":                       $tt-blue-600,
  "main-contrast":              contrast.color($tt-blue-600),

  // Notifications, badges, highlight "Next"
  "accent":                     $tt-orange,

  // Clickables: buttons, links, card hover effects
  "button":                     $tt-blue-600,
  "button-text":                contrast.color($tt-blue-600),

  // Web: top navigation bar
  // App: title bar & side menu
  "header":                     $white,
  "header-text":                $tt-darkblue-600,
);

// Customizable in admin
$theme-main:                    var(--theme-main);
$theme-accent:                  var(--theme-accent);
$theme-button:                  var(--theme-button);

// Contrasting colors for legibility
$theme-main-contrast:           var(--theme-main-contrast);
$theme-button-contrast:         var(--theme-button-contrast);

// Determined by contrast calculation
$theme-button-text:             var(--theme-button-text);

$theme-header:                  var(--theme-header);
// Determined by contrast calculation
$theme-header-text:             var(--theme-header-text);


/// Fixed, non-customizable colors

// Page background color
$theme-body:                    $tt-gray-50;

// Typography
$theme-text:                    $tt-darkblue-700;
$theme-heading:                 $tt-darkblue-700;
$theme-link:                    $tt-darkblue-600;

// States
$theme-neutral:                 $tt-darkblue-400;
$theme-neutral-light:           $tt-darkblue-300;
$theme-success:                 $tt-green;
$theme-success-light:           $tt-green-light;
$theme-warning:                 $tt-orange;
$theme-warning-light:           $tt-orange-light;
$theme-danger:                  $tt-red;
$theme-danger-light:            $tt-red-light;

// 100% score or "new competence"
$theme-full:                    $tt-gold;
$theme-full-light:              $tt-gold-light;


/// Effects for branding backgrounds

// Transparent overlay to soften a theme's background color
$_shade:                        transparency.black(8%);
$_shade-light:                  transparency.black(4%);
$_tint:                         transparency.white(80%);

$theme-shade-fx:                $_shade;
$theme-shade-fx:                linear-gradient($_shade, $_shade);
$theme-shade-light-fx:          linear-gradient($_shade-light, $_shade-light);
$theme-tint:                    $_tint;
$theme-tint-fx:                 linear-gradient($_tint, $_tint);
