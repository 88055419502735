/// Program cards on "home" screen

@use "@functions/map" as map;
@use "../variables" as *;

$_program-completed-theme: map.deep-get($card-themes, "program", "completed");

// @extend .card
.program {
  // Continue, Certificate etc.
  // .button.-large {
  //   --btn-width: 100%;
  // }

  // Finished program
  &.-completed {
    --THEME: #{$_program-completed-theme};
  }
}
