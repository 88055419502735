/// Fonts: Poppins (Latin)

// Regular: 400

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url("@fonts/Poppins/Poppins-Regular.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url("@fonts/Poppins/Poppins-Italic.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Semi-bold: 600

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url("@fonts/Poppins/Poppins-SemiBold.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  src: url("@fonts/Poppins/Poppins-SemiBoldItalic.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Bold: 700

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url("@fonts/Poppins/Poppins-Bold.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  src: url("@fonts/Poppins/Poppins-BoldItalic.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
