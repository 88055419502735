/// Icons

// @usage custom properties
// --THEME              The theme, can be inherited by parent

@use "sass:map";
@use "../variables" as *;

// Phosphor icons
// <i>[.ph|.ph-fill]
.icon {
  --icon-theme: var(--THEME); // Inherited: applied to all

  font-size: var(--icon-font-size, inherit);
  color: var(--icon-theme);

  h1 & {
    --icon-theme: #{$icon-default-theme};
  }

  /// Variants

  &.-large {
    --icon-font-size: 1.3333em;
  }

  &.-black {
    --icon-theme: black;
  }

  // Browsers/mobile icon
  &.-xlarge {
    --icon-font-size: 2em;
  }

  // .ph-spinner
  &.-spin {
    animation: spin $animation-speed-xslow infinite linear;
  }

  /// Specifics

  // Glowing lightbulb
  &[class*="lightbulb"] {
    text-shadow: 0 -2px 5px yellow;
  }

  /// Brands

  &.-android,
  &.-ios {
    width: $font-size-lg;
    fill: currentcolor;
  }
}

/// In a (big) circle
// - ~Alerts~
// - Activity
// - ~Event cards~
// - ~Event filter~
// [:is|:has](i)
.circle-icon {
  --icon-theme: var(--THEME, #{$icon-default-theme});
  --icon-font-size: #{$circle-icon-font-size};

  display: inline-grid;
  place-items: center;
  width: var(--icon-size, $circle-icon-size);
  height: var(--icon-size, $circle-icon-size); // Safari won't apply aspect-ratio?
  aspect-ratio: 1;
  line-height: 1; // Independent of parent
  color: var(--icon-text, $icon-default-text);
  background: var(--icon-theme);
  border-radius: $border-radius-circle;

  // Activity type
  &.-large {
    --icon-font-size: #{$circle-icon-font-size-lg};
    --icon-size: #{$circle-icon-size-lg};
  }

  /// Themes

  @each $name, $colors in $icon-themes {
    &.-#{$name} {
      --icon-theme: #{map.get($colors, "theme")};
      --icon-text: #{map.get($colors, "text")};
    }
  }
}
