/// Wallpapers, empty states

// @markup
// .wallpaper[.-compact,.-{THEME}]
//   h3
//     i.{icon}
//   {CONTENT: [p,ul]}
//   (a.button.-large)

// @usage custom properties
// --wp-theme           Sets the wallpaper's theme
// --wp-icon            Icon (font) size

@use "../variables" as *;

.wallpaper {
  --wp-theme: var(--THEME, #{$wallpaper-default-theme});

  display: grid;
  gap: $gap;
  justify-items: center;
  max-width: $wallpaper-max-width;
  padding: var(--tt-gap-sm);
  margin: auto; // block & inline, centers when in flex parent
  color: $wallpaper-default-theme;
  text-align: center;

  h3 {
    display: contents;
    margin-block: 0;

    i {
      font-size: var(--wp-icon, $wallpaper-icon-size-lg);
      color: var(--wp-theme);
    }

    // Green checkmark
    [class*="check"] {
      color: $theme-success;
    }
  }

  p,
  ol,
  ul {
    &:first-child {
      margin-block-start: 0;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }

  // Not .button
  a:not([class]) {
    font-weight: 600;
    color: inherit;
    text-decoration: underline var(--wp-theme);
  }

  /// Variations

  &.-compact {
    --wp-icon: #{$wallpaper-icon-size-sm};

    h3 {
      display: flex;
      gap: $inline-gap;
      align-items: center;
    }
  }

  /// Themes

  @each $name, $theme in $wallpaper-themes {
    &.-#{$name} {
      --THEME: #{$theme};
    }
  }
}
