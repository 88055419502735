/// Media Queries

@use "../_variables/base" as var;

/// Viewport widths

// < 480px
@mixin max-width-xs {
  @media (max-width: (var.$screen-xs - 1px)) {
    @content;
  }
}

// >= 480px
@mixin min-width-xs {
  @media (min-width: var.$screen-xs) {
    @content;
  }
}

// < 768px
@mixin max-width-sm {
  @media (max-width: (var.$screen-sm - 1px)) {
    @content;
  }
}

// >= 768px
@mixin min-width-sm {
  @media (min-width: var.$screen-sm) {
    @content;
  }
}

// // < 992px
// @mixin max-width-md {
//   @media (max-width: (var.$screen-md - 1px)) {
//     @content;
//   }
// }

// // >= 992px
// @mixin min-width-md {
//   @media (min-width: var.$screen-md) {
//     @content;
//   }
// }

// // < 1200px
// @mixin max-width-lg {
//   @media (max-width: (var.$screen-lg - 1px)) {
//     @content;
//   }
// }

// // >= 1200px
// @mixin min-width-lg {
//   @media (min-width: var.$screen-lg) {
//     @content;
//   }
// }

/// Viewport heights

// >= 800px
@mixin min-height-md {
  @media (min-height: var.$screen-height-md) {
    @content;
  }
}

/// Screen pixel density

@mixin retina {
  // @media (min-resolution: 144dpi)
  @media (resolution >= 144dpi) {
    @content;
  }
}
