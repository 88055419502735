/// Progress bars
// Program progress in .card

@use "../_variables/" as *;

$_progress-size: 45px;
$_progress-circle-width: 3px;

.progress-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $_progress-size;
  height: $_progress-size;

  // aspect-ratio: 1; // It's in flex
  padding: 0.25em;

  font-size: $font-size-xs;
  font-weight: 600;
  border: $_progress-circle-width solid var(--progress-theme, $theme-accent);
  border-radius: $border-radius-circle;

  &[data-progress="0"] {
    --progress-theme: #{$theme-neutral};
  }

  // &[data-progress="10"],
  // &[data-progress="20"],
  // &[data-progress="30"] {}
  // &[data-progress="40"],
  // &[data-progress="50"],
  // &[data-progress="60"] {}
  // &[data-progress="70"],
  // &[data-progress="80"],
  // &[data-progress="90"] {}

  &[data-progress="100"] {
    --progress-theme: #{$theme-success};
  }
}
