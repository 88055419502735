/// Segments, displays as a timeline

// @markup
// <section>.segment[.-open|.-locked|.-expired|.-completed]
//   <header>.segment__header
//     <span>.segment__title
//     .segment__status
//       i.{icon}
//   .segment__body
//     {CONTENT}

@use "@functions/color-transparency" as transparency;
@use "../variables" as *;

// Having the timeline and dot on the side
// = .card
.segment {
  --segment-theme: var(--THEME);
  --heading: var(--segment-theme);

  position: relative;
  background: $card-default-background;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;

  // Timeline
  &:not(:first-of-type) {
    &::before {
      position: absolute;
      inset: // -force newline-
        $timeline-offset-top // -force newline-
        auto // -force newline-
        calc(100% + $timeline-offset-bottom) // -force newline-
        $timeline-offset-left;
      z-index: -1;
      content: "";
      border-left: $timeline-width solid $timeline-color;
      transform: translateX(-50%);
    }
  }

  /// States
  // Note: cascading matters = importance of state

  // &.-open {
  //   --segment-theme: #{$theme-main};
  //   --THEME: #{$theme-main};
  // }

  &.-locked {
    --THEME: #{$theme-neutral};

    // color: var(--segment-theme);
    background-image: $gradient-stripes-dark;
    background-position: 0 0;

    &::before {
      border-left-style: dashed;
    }

    .segment__header {
      background-image: $gradient-stripes-dark;

      // Increase size and reposition by segment's padding for continuous overlapping gradient
      background-position: calc(-1 * var(--tt-gap)) calc(-1 * var(--tt-gap));
      background-size: 200% 200%;
    }
  }

  &.-expired {
    --THEME: #{$theme-danger};
  }

  &.-completed {
    --THEME: #{$theme-success};
  }
}

.segment__header {
  position: sticky;
  top: var(--sticky-header-height, 0);
  z-index: $zindex-sticky;
  display: flex;
  flex-wrap: wrap;

  // grid-template-columns: $segment-status-size 1fr auto;

  gap: $line-height-xs var(--tt-gap);
  place-content: center space-between;
  align-items: baseline;
  padding: var(--tt-gap-sm);
  padding-left: $gap + $segment-status-size + $gap-sm;

  // "Frosted glass"
  background: transparency.white(84%);
  backdrop-filter: blur(4px);

  // stylelint-disable-next-line declaration-empty-line-before
  border-radius: $card-border-radius;

  h2 {
    font-size: $font-size;
  }

  // Status icon
  > i {
    position: absolute;
    top: $gap - ($segment-status-size - $gap) * 0.5;
    left: $gap;
    font-size: $segment-status-size;
    color: var(--THEME, $theme-neutral);
  }
}

.segment__body {
  padding: var(--tt-gap-sm);
}

/// Meta info: start date, deadline, attachments

.segment__meta {
  display: flex;
  flex-wrap: wrap;
  gap: $line-height-xs var(--tt-gap-sm);
  font-size: $font-size-sm;
  line-height: 1;
}

.segment__meta-item {
  display: inline-flex;
  gap: $inline-gap;
  align-items: baseline;
  // color: var(--THEME, $theme-neutral);
  color: $theme-neutral;
}
