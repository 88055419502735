/// Animations

@use "../variables" as *;

// Fade effect on all navigation links and buttons
header a,
nav a {
  &,
  & i,
  &::before,
  &::after {
    transition: all $interaction-animation-speed $animation-timing;
  }
}

// Blinking (Recording dot)
@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// Loaders
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/// Full score bar
// https://daneden.github.io/animate.css/

@keyframes bounce-in {
  0% {
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jello {
  0% {
    transform: translate3d(0, 0, 0);
  }

  11.1% {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-9deg) skewY(-9deg);
  }

  33.3% {
    transform: skewX(4.5deg) skewY(4.5deg);
  }

  44.4% {
    transform: skewX(-2.25deg) skewY(-2.25deg);
  }

  55.5% {
    transform: skewX(1.125deg) skewY(1.125deg);
  }

  66.6% {
    transform: skewX(-0.5625deg) skewY(-0.5625deg);
  }

  77.7% {
    transform: skewX(0.2813deg) skewY(0.2813deg);
  }

  88.8% {
    transform: skewX(-0.1406deg) skewY(-0.1406deg);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
