// Assessment report: Assessment, Exercises, Conclusion
// Timeline events pagination
// Timeline events browser

@use "@functions/units" as unit;
@use "../variables" as *;

$pagination-default-theme: var(--btn-theme, $gray-600); // = $button-default-theme
$pagination-link-size: unit.rem(39px); // = .button computed height
$pagination-disabled-color: $theme-neutral;
$pagination-disabled-bg: transparent;

// Markup & class names by will_paginate, Bootstrap
// Note: same class name for two nested elements:
// <div>.pagination
//   <ul>.pagination
.pagination {
  // display: contents;

  @at-root ul#{&} {
    // Display as default .button
    --btn-theme: #{$theme-button};
    --btn-c: var(--btn-theme);
    --btn-bg: transparent;
    --btn-brd: var(--btn-theme);

    display: flex;
    gap: $inline-gap;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  // Exclude other links like Continue buttons
  li > :where(a, span) {
    display: inline-flex; // no `flex`: we don't need 100% width everywhere
    gap: $inline-gap;
    align-items: center;
    justify-content: center;
    min-width: $pagination-link-size;
    height: $button-height;
    padding-inline: $button-padding-inline;
    font-size: $button-font-size;
    color: var(--btn-c, $white);
    text-decoration: none; // = <a> default
    white-space: nowrap;
    background: var(--btn-bg, $pagination-default-theme);
    border: $button-border-width solid var(--btn-brd, $pagination-default-theme);
    border-radius: $button-border-radius;
  }

  // <li>
  .previous_page,
  .prev {
    margin-inline-end: auto;
  }

  .next_page,
  .next {
    margin-inline-start: auto;
  }

  .active {
    --btn-c: #{$white};
    --btn-bg: var(--btn-theme);
    --btn-brd: var(--btn-theme);
  }

  .disabled {
    --btn-c: #{$pagination-disabled-color};
    --btn-bg: #{$pagination-disabled-bg};
    --btn-brd: #{$pagination-disabled-color};

    pointer-events: none;
    visibility: hidden;
  }
}
