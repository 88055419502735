/// User picture
// See presenters/user_presenter.rb
// <img>.avatar
// <span>.avatar - Initial (P) when no avatar uploaded yet

@use "../variables" as *;

.avatar {
  --avatar-size: #{$avatar-size};

  width: var(--avatar-size);
  height: var(--avatar-size);
  overflow: hidden; // For broken images
  border-radius: $border-radius-circle;

  // Activity card footer
  &.-tiny {
    --avatar-size: #{$avatar-size-xs};
  }

  &.-small {
    --avatar-size: #{$avatar-size-sm};
  }

  // Profile
  &.-large {
    --avatar-size: #{$avatar-size-lg};
  }

  // Initial (A) when no avatar uploaded yet
  &[data-initial] {
    display: grid;
    place-content: center;
    font-size: calc(var(--avatar-size) * 0.75);
    font-weight: 600;
    line-height: 1;
    color: $white;
    background: var(--avatar-theme, $gray-400);
    border: $border-width solid transparent;
  }

  // Unknown/removed user / non-alphabetic initial
  // :has(.ph-user)
  &[data-initial="-"] {
    --avatar-theme: #{$avatar-0};

    i {
      font-size: 1.5em;
      transform: translateY(8%);
    }
  }

  // Alix
  &[data-initial="A"] {
    --avatar-theme: #{$avatar-alix};
  }

  &[data-initial="a"],
  &[data-initial="i"],
  &[data-initial="q"],
  &[data-initial="y"] {
    --avatar-theme: #{$avatar-1};
  }

  &[data-initial="b"],
  &[data-initial="j"],
  &[data-initial="r"],
  &[data-initial="z"] {
    --avatar-theme: #{$avatar-2};
  }

  &[data-initial="c"],
  &[data-initial="k"],
  &[data-initial="s"] {
    --avatar-theme: #{$avatar-3};
  }

  &[data-initial="d"],
  &[data-initial="l"],
  &[data-initial="t"] {
    --avatar-theme: #{$avatar-4};
  }

  &[data-initial="e"],
  &[data-initial="m"],
  &[data-initial="u"] {
    --avatar-theme: #{$avatar-5};
  }

  &[data-initial="f"],
  &[data-initial="n"],
  &[data-initial="v"] {
    --avatar-theme: #{$avatar-6};
  }

  &[data-initial="g"],
  &[data-initial="o"],
  &[data-initial="w"] {
    --avatar-theme: #{$avatar-7};
  }

  &[data-initial="h"],
  &[data-initial="p"],
  &[data-initial="x"] {
    --avatar-theme: #{$avatar-8};
  }
}
