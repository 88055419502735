/// Choices
// Custom checkboxes & radio buttons with icons

// @markup
// Optionally in list
// <ul|ol>.choice-list
//   <li>.choice
// -
// <div|span|li>.choice
//   (<label>)                          *1 Radio buttons have extra wrapping <label>
//     <input>.choice__input
//     <label>.choice__label            Feedback requested: <div>
//       <span>.choice__icon-stack
//         <span>.choice__icon.-unchecked
//         <span>.choice__icon.-checked

@use "@mixins/helpers" as helpers;
@use "../variables" as *;

// <ul>
.choice-list {
  display: grid;
  gap: var(--tt-gap-sm);
  padding: 0;
  margin: 0;
  list-style: none;
}

// <div|span|li>
.choice {
  // Wrapping label for radio buttons, see *1
  > label:not([class]) {
    display: contents;
  }
}

// <label>
.choice__label {
  display: inline-flex;
  gap: $inline-gap;
  align-items: center;
  color: var(--choice-text);
  cursor: pointer;

  &:hover,
  &:focus-within {
    --choice-text: #{$choice-hover-color};
    --choice-icon-color: currentcolor;
    --choice-icon-fx-scale: 1.08;
    // --choice-bg: #{$choice-hover-color}; // Only highlight outline
    --choice-brd: #{$choice-hover-color};
  }

  &.-disabled,
  &[aria-disabled="true"],
  fieldset:disabled & {
    --choice-text: #{$theme-neutral};
    --choice-bg: #{$gray-100};
    --choice-brd: #{$gray-200};
    --choice-icon-fx-scale: 0.9;

    cursor: default;
  }

  &.-block {
    display: flex;
    gap: $inline-gap-lg;
    // align-items: start; // Center looks off, but sometimes start as well
    // width: min(100%, $card-max-width);
    padding: var(--tt-gap-sm);
    margin-inline: auto;
    background: var(--choice-bg, $white) $theme-tint-fx;
    border: $border-width solid var(--choice-brd, $gray-200);
    border-radius: $border-radius-lg;
  }

  // Requested feedback from friend
  &.-success {
    --choice-text: #{$theme-success};

    cursor: default;
  }
}

// <span>
.choice__icon-stack {
  display: inline-grid;
}

// <span>
.choice__icon {
  grid-row: 1;
  grid-column: 1;
  font-size: $icon-font-size-lg;
  line-height: 1;
  color: var(--choice-icon-color, $choice-unchecked-color);
  opacity: var(--choice-icon-opacity);
  transition: all $animation-speed-fast $animation-timing;
  transform: scale(var(--choice-icon-fx-scale, var(--choice-icon-scale)));

  // = default
  &.-unchecked {
    --choice-icon-scale: 1;
    --choice-icon-opacity: 1;
  }

  &.-checked {
    --choice-icon-color: #{$choice-checked-color};
    --choice-icon-scale: 0.6;
    --choice-icon-opacity: 0;
  }
}

// <input type=["checkbox"|"radio"]>
.choice__input {
  // Hide form input
  @include helpers.visually-hidden;

  // Accessibility
  &:focus-visible {
    + .choice__label {
      @include helpers.accessibility-focus;
    }
  }

  &:checked {
    + .choice__label {
      --choice-bg: #{$choice-checked-color};
      --choice-brd: #{$choice-checked-color};

      .choice__icon {
        &.-unchecked {
          --choice-icon-opacity: 0;
          --choice-icon-scale: 0.6;
        }

        &.-checked {
          --choice-icon-opacity: 1;
          --choice-icon-scale: 1;
        }
      }
    }
  }

  &[type="radio"] {
    &:checked {
      + .choice__label {
        --choice-text: #{$choice-checked-color};
      }
    }
  }
}
