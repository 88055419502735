/// Alerts

// @types
// 1. Regular alerts in page    Small alerts, for contextual information/highlighting: "No attempts left"
// 2. Dismissible alerts        Wide alerts floating on top, with x-button to close
// 3. Persistent                Placed inside .alert-group. Stays on top of the page, full screen width
// 4. Program-related alerts    Placed inside .program_alerts. E.g. "You've finished the program"

// @markup
// (section.alert-group)
//   .alert[{TYPE: .-dismissible|.-persistent},{THEME}]
//     .alert__inner
//       (button.close)
//       .alert__icon
//       .alert__body
//         (h3)
//         {CONTENT: [p,ul]}
//         (.button)

// @usage custom properties
// --THEME              the overall theme that will also be inherited by icons, buttons etc.
// --alert-theme        sets the alert's theme
// --alert-width        directly sets the width, fallback to smart max-width
// --alert-inner-width  sets an inner width: persistent alerts need content contained
// --alert-radius       border-radius: disable (`0`) for persistent alerts

@use "../variables" as *;

.alert {
  --THEME: #{$alert-default-theme};
  --alert-theme: var(--THEME, #{$alert-default-theme});

  // width: var(--alert-width, min(100%, $alert-max-width));
  width: var(--alert-width, 100%);
  margin-inline: auto;
  color: $alert-default-text;
  background: var(--alert-theme) $theme-tint-fx;
  border-radius: var(--alert-radius, $alert-border-radius);

  // Can have a badge (Invitations)
  h3 {
    display: flex;
    gap: $inline-gap;
    align-items: center;
    margin: 0;
    font-weight: 600;
  }

  p,
  ol,
  ul {
    &:first-child {
      margin-block-start: 0;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }

  // Neither .button nor .close, nor others, future or forgotten
  a:not([class]) {
    font-weight: 600;
    color: inherit;
    text-decoration: underline var(--alert-theme);
  }

  // Dismiss × button
  .close {
    order: 3;
  }

  /// Types

  // Floating on top of the screen, dismissible
  &.-dismissible {
    --alert-width: var(--OFF);

    position: fixed;
    inset: $gap $gap auto;
    z-index: $alert-z-index;
    max-width: $alert-max-width;
    box-shadow: $overlay-shadow;
  }

  // Full width alerts on top of the screen
  &.-persistent {
    --alert-width: 100%;
    --alert-inner-width: #{$alert-persistent-max-width};
    --alert-radius: 0;
  }

  &.-small {
    --alert-width: #{$component-max-width};
  }

  /// Themes

  @each $name, $color in $alert-themes {
    &.-#{$name} {
      --THEME: #{$color};
    }
  }
}

.alert__inner {
  position: relative; // For .close
  display: grid;

  // Icon-size on the right for optional .close, keeping body content centered
  grid-template-columns: auto 1fr $circle-icon-size-lg;

  // stylelint-disable-next-line declaration-empty-line-before
  gap: $gap;
  max-width: var(--alert-inner-width);
  padding: var(--tt-gap-sm) var(--tt-gap);
  margin-inline: auto;
}

.alert__icon {
  --icon-theme: var(--THEME, #{$icon-default-theme});

  font-size: $alert-icon-font-size;
  line-height: 1;
  color: var(--icon-theme);
}

.alert__body {
  display: grid;
  gap: $line-height-sm;
  align-self: center;
  justify-items: start;
}

/// Groups

// .alert-group {}

// Containing program-related alerts within program screens
// E.g. "You've finished the program"
// #program-alerts {}
