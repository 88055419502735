/// Navigation tabs, wizards

@use "../_mixins/mq" as mq;
@use "../variables" as *;

// Assessment result navigation
// @extends .navigation
// <nav>
.navigation.-tabs {
  justify-content: center; // Overrides .navigation space-between

  // Has step numbers and arrows: 1 > 2 > 3
  li {
    --badge-theme: #{$theme-neutral};

    // [aria-current="step"]
    &[aria-current] {
      --link: #{$theme-button};
      --badge-theme: #{$theme-main};
    }
  }

  // Stack on small screen
  @include mq.max-width-xs {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }

  // Arrow between nav items on large screens
  @include mq.min-width-xs {
    li {
      display: contents;

      &:not(:last-child) {
        // = .ph.ph-caret-right
        &::after {
          font-family: Phosphor, monospace !important;
          font-size: 12px;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          line-height: 1;
          text-transform: none;
          content: "\ea00";
          speak: never;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
}
