/// Typography

@use "../_mixins/mq" as mq;
@use "../variables" as *;

/// Headings
// By default, top & bottom margins are needed for titles in texts (Situation, Assignment etc.)
// Adjust for components in their respective classes

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
  font-weight: inherit;
  color: var(--heading, $theme-heading);
}

%heading-spacing-lg {
  margin-block: 0;
  line-height: $heading-line-height-lg;
}

%heading-spacing-md {
  margin-block: $line-height $line-height-sm;
  line-height: $heading-line-height-md;
  letter-spacing: -0.025ch;
}

%heading-spacing-sm {
  margin-block: $line-height-sm;
  line-height: $heading-line-height-sm;
}

// Page title
h1 {
  @extend %heading-spacing-lg;

  font-size: $font-size-h1;

  @include mq.max-width-xs {
    font-size: $font-size-h3;
  }
}

// Content section title
h2 {
  @extend %heading-spacing-lg;

  font-size: $font-size-h2;

  @include mq.max-width-xs {
    font-size: $font-size-h3;
  }
}

// Title of paragraphs
h3 {
  @extend %heading-spacing-md;

  font-size: $font-size-h3;

  @include mq.max-width-xs {
    font-size: $font-size-h4;
  }
}

// Titles in small blocks, e.g. sidebar
h4 {
  @extend %heading-spacing-sm;

  font-size: $font-size-h4;
}

// Not used
h5 {
  @extend %heading-spacing-sm;

  font-size: $font-size-h5;
}

// Not used
h6 {
  @extend %heading-spacing-sm;

  font-size: $font-size-h6;
}

/// Text & variations

p {
  max-width: $text-max-width;
  margin-block: $line-height-sm;
  // line-height: $font-line-height;
}

b,
strong {
  font-weight: 600; // A little less bold than browser default (800)
}

// i,
// em {
//   font-style: italic; // = browser default
// }

// small {
//   font-size: 12px; // $font-size-sm;
// }

ul,
ol {
  margin-block: $line-height-sm;

  ul,
  ol {
    margin-block-end: 0;
  }
}

/// Time
// Notations like answer saved on, deadline, upcoming, etc.

time {
  font-size: $font-size-sm;
  line-height: 1;
  color: $theme-neutral;

  i {
    color: inherit;
  }
}

// "New exercises in 2 days"
.upcoming {
  color: $theme-accent;
}

// "Deadline in 2 days"
.deadline {
  color: $theme-danger;
}

/// Text variants
// Replace these with BEM modifier classes at one point

.text-center {
  text-align: center;
}

.text-small {
  font-size: $font-size-sm;
}

.text-blur {
  filter: blur(4px);
}

.text-success {
  color: $theme-success !important;
}

.text-warning {
  color: $theme-warning !important;
}

.text-danger {
  color: $theme-danger !important;
}

.text-full {
  color: $theme-full !important;
}

.text-muted,
.text-neutral {
  color: $theme-neutral !important;
}

/// Misc

// Output of user generated content via Trix from admin, coach
// Layout as <p>aragraph
.trix {
  // max-width: $text-max-width;
  // margin-block: var(--tt-gap);
  word-break: break-word; // Eg. long URLs added by admin

  a {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }

  h1 {
    font-size: $font-size-h3;
  }
}
