/// Cards

// @markup
// .card[{VARIANT},{THEME},{TYPE: .program|.event|.competence|.friend|.feedback}]
//   a                          z: 1; Link covering the whole card
//   (.card-flag)               z: 3
//   (.card__poster)
//     (img)
//   .card__header
//     [[img|span].avatar |img.thumb]
//     .card__heading
//       [h1|h2|h3|h4|h5|h6]    z: 2; Mostly h3 or h4
//       .card__subtitle
//   .card__body
//     {CONTENT}
//     (ul.card__list)          z: 2
//   .card__footer
//     .card__meta              Icons, time stamp
//     .card__cta               z: 2; CTA or status
//       a(.button)

// @usage custom properties
// --THEME              the overall theme that will also be inherited by icons, buttons etc.
// --card-theme         sets the card's theme
// --card-bg            sets the background directly
// --card-theme-tint    "boolean" enabling tinting the background
// --card-max-width     max-width
// --card-radius        border-radius
// --card-shadow        box-shadow

@use "../variables" as *;

// <div|li>
.card {
  // --THEME: var(--OFF); // @TODO set correct theme
  --card-theme: var(--THEME);
  --card-theme-tint: var(--OFF);
  --card-max-width: #{$card-max-width};

  position: relative;
  display: grid;
  grid-template-rows: min-content; // Force min-content on Safari, otherwise rows will be too high :(
  gap: var(--tt-gap-sm);

  // 100%: grid container + margin-inline collapses card to min-content
  width: min(100%, var(--card-max-width, $card-max-width));

  // stylelint-disable-next-line declaration-empty-line-before
  padding: var(--tt-gap-sm);
  margin-inline: auto;
  // background-color: var(--card-theme, var(--card-bg, $white));
  background-color: var(--card-bg, $card-default-background);
  background-image: var(--card-theme-tint, $theme-tint-fx);
  border-radius: var(--card-radius, $card-border-radius);
  box-shadow: var(--card-shadow, $card-box-shadow);
  // transition: all $interaction-animation-speed $animation-timing;

  > form {
    display: contents;
  }

  // h3 {
  //   margin: 0;
  // }

  // p,
  // ol,
  // ul {
  //   margin: 0;
  // }

  progress {
    border-radius: 0;

    &::-moz-progress-bar {
      border-radius: 0;
    }

    &::-webkit-progress-value {
      border-radius: 0;
    }
  }

  /// User interaction

  // Card-covering link, increase card's click area (non-focusable: tabindex="-1")
  // See card_link(path) @ app/helpers/programs/card_helper.rb
  > a {
    position: absolute;
    inset: 0;
    z-index: $card-zindex-hitarea;
  }

  // Position other links and buttons over that link to be accessible
  // :where(.card__body, .card__footer) :where(a, button):not([class]) ?
  :where(.card__body, .card__footer) :where(a, button),
  // TraineeFeedbackOpinion: agree/disagree to segment result feedback
  [data-react-component] {
    position: relative;
    z-index: $card-zindex-inner-link;
  }

  [data-react-component] {
    display: contents;
  }

  // data-href as fallback for Firefox https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
  &[data-href],
  &:has(> a) {
    &:hover,
    &:focus-within {
      // --card-theme: #{$theme-button};
      --heading: #{$theme-heading};
      // --card-bg: #{$gray-100};
    }
  }

  /// Variations

  // Activities and in sidebars
  &.activity,
  &.-small {
    --card-title: #{$font-size};
    --card-radius: #{$border-radius-md};
  }

  // Skills (competences), wrapping wallpapers
  &.-large {
    --card-max-width: #{$layout-content-max-width};
  }

  /// Themes

  // stylelint-disable-next-line no-descending-specificity
  &:where(.-muted, .-active) {
    --card-bg: var(--card-theme);
    --card-theme-tint: var(--ON);
  }

  // Unchecked criterion (result)
  &.-muted {
    --THEME: #{$theme-neutral};
  }

  // Peer feedback: criterion not checked
  &.-danger {
    --THEME: #{$theme-danger};
  }

  // Checked criterion (exercise)
  &.-active {
    --THEME: #{$theme-button};
  }
}

// Program or RolePlay thumb on top
// <figure>
.card__poster {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 4 / 3;
  background: $gray-200;
  border-radius: $border-radius;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}

.card__header {
  display: flex;
  gap: var(--tt-gap-sm);
  align-items: center;

  // h1, h2, h3 are known to be used in the header
  // what about h4, h5, h6?
  :where(h1, h2, h3, h4, h5, h6) {
    display: flex;
    flex: 1; // When there is no .card__heading
    gap: $inline-gap;
    align-items: center;
    margin: 0;
    font-size: var(--card-title, $font-size-h3);
    word-break: break-word; // Prevents long titles from breaking UI
  }
}

// Contains multiple <h>eadings in flex parent
.card__heading {
  flex: 1;
}

// Card title, e.g. Program title. @UNUSED
// <h3>, can contain link with the main CTA
// .card__title

// Subtitle, e.g. Exercise or Segment title on event cards
// .card :where(h1, h2, h3) + *
.card__subtitle {
  font-size: $font-size-h4;
  color: $theme-text;
}

.card__body {
  display: grid; // Downside: width stretching of children
  gap: $line-height-sm;
  // justify-items: start; // Downside: width collapsing of children
  // => Let children decide their margins

  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

// Links or info, with icon left
// "Exercises (2)"
// "New exercises in 2 days"
// <ul>
.card__list {
  --link: #{$theme-text};
  --hover: #{$theme-button};

  display: grid;
  padding: 0;
  margin: 0;
  overflow: hidden;
  color: var(--link);
  list-style: none;
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  li {
    + li {
      border-top: $border-width solid $border-color;
    }

    :where(a, time) {
      display: flex;
      gap: $gap-sm;
      align-items: baseline;
      padding: $gap-sm $gap;
    }
  }

  a {
    &:hover,
    &:focus-visible {
      background: $gray-100;
    }
  }

  // i:first-child => doesn't work, text node doesn't count as a child
  i {
    color: $card-list-icon-theme;
  }

  .badge {
    margin-inline-start: auto;
  }
}

.card__footer {
  --link: #{$gray-600}; // <a> inherits

  display: flex;
  gap: $gap-sm;
  align-items: center;
  margin-block-start: auto;
  font-size: $font-size-sm;
  color: var(--link);
}

// Position over the card-covering link, allowing tooltips on :hover
.card__meta {
  position: relative;
  z-index: $card-zindex-inner-link;
}

// Call-to-Action, aligns right
.card__cta {
  display: flex;
  gap: $inline-gap;
  align-items: center;
  margin-inline-start: auto;
}

/// Groups
// E.g. programs, activities, events, feedback etc.

// <div|ol|ul>
.card-list,
.card-grid {
  display: grid;
  gap: var(--tt-gap-sm);
  justify-items: center;
  padding-inline: 0;
  margin-block: 0;
  list-style: none;
}

.card-grid {
  grid-template-columns: repeat(auto-fill, minmax($card-min-width, 1fr));
}
