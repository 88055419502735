/// Layout settings

/// Stylelint: exclude rules that allow human-friendly sorting, grouping and alignment
// stylelint-disable scss/dollar-variable-colon-space-after
// stylelint-disable scss/dollar-variable-empty-line-before
// stylelint-disable scss/double-slash-comment-empty-line-before

@use "@functions/color-transparency" as transparency;
@use "@functions/units" as unit;
@use "@variables/color-palette" as *;
@use "./base" as *;
@use "./theme" as *;


/// Web (a.k.a. Desktop) header

$web-header-height:             unit.rem(45px, $font-size--px-base); // Computed
$web-header-shadow:             0 2px 4px -1px transparency.black(30%);


/// Segments

$_segment-padding:              $gap;
$_segment-margin:               $gap-2xl;

$segment-status-size:           unit.rem(24px);

$timeline-width:                2px;
$timeline-offset-top:           -($_segment-margin - $_segment-padding * 0.5);
$timeline-offset-bottom:        $_segment-padding * 0.5;
$timeline-offset-left:          $gap + $segment-status-size * 0.5;
$timeline-color:                $gray-200;
