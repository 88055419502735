/// TrainTool VideoPlayer React component

@use "@functions/color-transparency" as transparency;
@use "@functions/units" as unit;
@use "../_mixins/mq" as mq;
@use "../variables" as *;

$vp-icon-shadow: inset 0.5px 1px 2px transparency.black(24%); // = $icon-shadow

// Wrapper
// [data-react-component="VideoPlayer"] {}

.ttvp-videoplayer {
  --ttvp-spacing: #{$gap-sm};
  --ttvp-spacing-sm: #{$gap-sm};

  position: relative; // Contain everything
  max-width: $media-width;
  // max-height: $media-max-height;
  // margin-inline: auto;
  overflow: hidden; // Contain everything
  color: $gray-100;
  user-select: none;
  background: $media-bg;
  border-radius: $border-radius-md;

  @include mq.min-width-xs {
    --ttvp-spacing: #{$gap};
  }
}

.ttvp-video {
  display: flex;
  justify-content: center;
  aspect-ratio: 16/9;
}

// Extends click area
.ttvp-ui-overlay {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
}

.ttvp-ui-overlay-icon {
  backdrop-filter: blur(4px);
  border-radius: $border-radius-circle;
  box-shadow: $vp-icon-shadow, $component-shadow;
  opacity: 0.8;
  transform: translateY(-50%);

  // = .circle-icon.-large
  i {
    --THEME: #{transparency.alpha($media-bg, 50%)};
  }

  &.-hide {
    visibility: hidden;
  }

  &.-show {
    visibility: visible;
  }
}

.ttvp-ui {
  // Invalid At Computed Value Time (IACVT):
  // https://projects.verou.me/talks/dynamic-css-secrets/#iacvt
  --ttvp-ui-show: ""; // Causing IACVT => browser default = Show UI
  --ttvp-ui-hide: initial; // Forcing var() fallback = Hide UI
  --ttvp-ui: var(--ttvp-ui-show); // Show by default

  position: absolute;
  inset: auto 0 0;
  display: grid;
  gap: var(--ttvp-spacing-sm);
  padding: var(--ttvp-spacing);
  font-size: $font-size-sm;
  color: $gray-100;
  visibility: var(--ttvp-ui, hidden);
  background: transparency.alpha($media-bg, 60%);
  backdrop-filter: blur(4px);
  border-radius: $border-radius-xxs $border-radius-xxs 0 0;
  box-shadow: $component-shadow;
  opacity: var(--ttvp-ui, 0);
  transition: none $animation-speed-fast $animation-timing;
  transition-property: opacity, transform, visibility;

  &.-hide {
    --ttvp-ui: var(--ttvp-ui-hide);
  }

  &:focus-within,
  &.-show {
    --ttvp-ui: var(--ttvp-ui-show);
  }
}

.ttvp-controls {
  display: flex;
  gap: var(--ttvp-spacing);
  align-items: center;

  // .button.-circle.-small
  .button.-circle {
    --btn-width: 2rem;
    --btn-height: 2rem;
    --btn-theme: #{$gray-100};
    --btn-bg: transparent;
    --btn-brd-fx: var(--OFF);
    --btn-bg-fx: var(--OFF);

    &:hover,
    &:focus-visible {
      --btn-bg: #{transparency.white(24%)};
    }
  }
}

.ttvp-control-playpause {
  font-size: $font-size;
}

// .ttvp-control-volume {}

// .ttvp-control-time {}

// Pushes other UI controls to the sides
.ttvp-control-spacer {
  flex: 1;
}

.ttvp-control-progress {
  position: relative;
  accent-color: $gray-100;

  // input[type="range"] {}
  input {
    padding: 0;
    margin: 0;
  }
}

// *1 Allow clicking on range input underneath timestamps
.ttvp-control-timestamps {
  $timestamp-size: unit.rem(16px); // Same as Chrome's input[range]>thumb

  position: absolute;
  inset: 0 ($timestamp-size * 0.5);
  display: flex;
  align-items: center;
  line-height: 0;
  pointer-events: none; // *1

  ul {
    display: contents;
    list-style: none;
  }

  // .ttvp-control-timestamp
  li {
    // Old-fashioned, always works
    position: absolute;
    // top: 0;
    left: var(--timestamp-x, 0);
    transform: translateX(-50%);

    // Nicer, but need to figure out which pixels (% is relative to self)
    // transform: translateX(var(--timestamp-x, 0));
  }

  button {
    display: grid;
    place-items: center;
    width: $timestamp-size;
    height: $timestamp-size;
    aspect-ratio: 1;
    padding: 0;
    font-size: 12px;
    color: $white;
    pointer-events: all; // *1
    cursor: pointer;
    background: $theme-success;
    border: 0;
    border-radius: $border-radius-circle;
  }
}
