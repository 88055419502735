/// Buttons

// @themes                      Usage (Example)
// [default: no theme]          Action without consequense (Cancel, Close, Next/Previous)
// .-primary                    Main CTA, mandatory or suggested next step (Start exercise)
// .-success                    Completed action (Exercise completed)
// Note: buttons inside components (e.g. alert) inherit theme's color via `var(--THEME)`

// @usage custom properties
// --THEME              The overall theme that will also be inherited by icons, badges etc.
// --btn-theme          Sets the button's theme
// --btn-text           Private var: sets the text color
// --btn-bg             Private var: sets the background color
// --btn-brd            Private var: sets the border color
// --btn-shadow         Private var: sets the box shadow

@use "sass:map";
@use "@mixins/helpers" as helpers;
@use "../_mixins/mq" as mq;
@use "../variables" as *;

.button {
  --btn-theme: var(--THEME, #{$button-default-theme}); // Inherited: applied to all

  display: inline-flex; // Not `flex`: will cause 100% width in flex parents
  gap: $inline-gap; // Between text node and icon
  align-items: center;
  justify-content: center;
  width: var(--btn-width, max-content);
  height: var(--btn-height, $button-height);
  padding-inline: $button-padding-inline;
  font-family: inherit;
  font-size: $button-font-size;
  font-weight: 600;
  line-height: 1;
  text-decoration: none; // = <a> default
  cursor: pointer; // <button> doesn't get pointer by browser default
  border: 0;
  border-radius: $button-border-radius;
  transition: all $interaction-animation-speed $animation-timing;

  // Theme styles
  // stylelint-disable order/properties-order
  // color uses --THEME instead of --btn-theme,
  // so it inherits from parent and allows different colors for text and icon
  color: var(--btn-text, var(--THEME, $button-default-text));
  background-color: var(--btn-bg, $button-default-background);
  background-image: var(--btn-bg-fx);
  box-shadow:
    // Border tint, softening the themed border
    inset 0 0 0 $button-border-width
      hsl(0deg 0% 100% / var(--btn-brd-fx, $button-border-default-fx)),
    // Themed border
    inset 0 0 0 $button-border-width var(--btn-theme),
    // Shadow: default: -1px = hidden
    var(--btn-shadow, $button-no-shadow);
  // stylelint-enable order/properties-order

  // Touch devices get taller buttons, improving hit area for fingers
  @media (hover: none) and (pointer: coarse) {
    --btn-height: #{$button-height-touch};

    // touch-action: manipulation; // Allow panning and zooming on mobile
    // user-select: none; // Text inside is not selectable, improves long press on mobile
  }

  // Small button
  &.-small {
    --btn-height: min(100%, #{$button-height * 0.75});
  }

  // Big block button
  &.-large {
    --btn-width: min(100%, #{$button-max-width});

    position: relative;

    // Checkmark for completed preparation, exercise
    i[class*="check"] {
      position: absolute;
      left: $button-padding-inline;
      font-size: $circle-icon-font-size-lg;
    }

    // Counter for attempts left
    .badge {
      position: absolute;
      left: $button-padding-inline;
    }
  }

  &.-medium {
    --btn-width: min(100%, #{$button-mid-width});
    position: relative;
  }

  &.-circle {
    --btn-width: #{$button-height};
    --btn-height: #{$button-height};

    aspect-ratio: 1; // Not all browsers respect this properly (Safari)
    border-radius: $border-radius-circle;
  }

  /// Themes

  @each $name, $colors in $button-themes {
    &.-#{$name} {
      --THEME: #{map.get($colors, "theme")}; // Also applies to children like icons and badges
      // --btn-theme: #{map.get($colors, "theme")}; // Only applies to button
      --btn-text: #{map.get($colors, "text")};
      --btn-bg: #{map.get($colors, "background")};

      @if map.get($colors, "style") == "filled" {
        --btn-hover-bg-fx: #{$button-hover-shade};
        --btn-brd-fx: #{$button-border-no-fx};
        --btn-shadow: #{$button-shadow};
      } @else {
        --btn-hover-bg-fx: #{$button-hover-tint};
      }
    }
  }

  /// Specific

  &.-android,
  &.-ios {
    + span {
      font-style: italic;
      color: $theme-neutral;
    }

    // = .-large on mobile screen sizes
    @include mq.max-width-xs {
      --btn-width: min(100%, #{$button-max-width});
    }
  }

  /// Icons

  // > .ph
  > i {
    // line-height: $button-line-height;
    color: var(--btn-text, var(--btn-theme, $button-default-theme));

    // Back, Previous, Next
    // &[class*="angle"]
    // &[class*="arrow"]

    // < Back/Previous
    &[class*="left"] {
      order: -1;
    }

    // Next >
    &[class*="right"] {
      order: 1;
    }
  }

  /// Interaction

  &:hover,
  &:focus-visible,
  &:focus-within {
    --btn-bg-fx: var(--btn-hover-bg-fx, #{$button-hover-tint});
    --btn-brd: var(--btn-theme, var(--btn-hover-brd));
    --btn-brd-fx: #{$button-border-hover-fx};
  }

  &:focus-visible {
    @include helpers.accessibility-focus(var(--btn-theme, dodgerblue));
  }

  &.-active,
  &:active {
    --btn-bg-fx: #{$button-active-shade};
    --btn-brd: var(--btn-theme, var(--btn-hover-brd));
    --btn-brd-fx: #{$button-border-hover-fx};
  }

  &.-disabled, // Note: Prefer using attributes below over class
  &:disabled,
  &[aria-disabled="true"],
  fieldset:disabled & {
    --btn-bg-fx: var(--OFF);
    --btn-brd: var(--btn-theme);
    --btn-brd-fx: #{$button-border-no-fx};
    --btn-shadow: #{$button-no-shadow};

    cursor: not-allowed;
    opacity: 0.6;
  }

  // @TODO: BS classes, replace with .-
  &.active,
  &.disabled {
    border: 2px dashed hotpink !important;
  }
}

/// Groups

// Contains 1-2 buttons, sometimes also an other component like .alert, info text etc.
// flex instead of grid because it can contain just 1 button and should always center
.button-group {
  display: flex;
  flex-direction: column;
  gap: $gap-sm;
  align-items: center;

  // Only for Exercise buttons
  // Aligns button in a row on larger screens, keeps stacked on smaller screens
  &.-inline {
    flex-flow: row wrap;
    justify-content: center;
  }
}

// Multiple buttons (.-circle) in a single row
// Certificate share links
// Peer feedback criteria
// Not: Review scores
.button-bar {
  display: inline-flex; // Why inline?
  flex-wrap: wrap;
  gap: $inline-gap;
  align-items: center;
}
