/// Web header
// a.k.a. desktop-only, hidden on mobile/app views
// header.web-header            z: 1000  Hidden on mobile and when in "full screen" views
//   a[rel="home"]
//   nav.main-navigation
//   .help
//   (.environment)
//   (.lms-session-close)       Exit TT, return to LMS
//   .user

@use "../_mixins/mq" as mq;
@use "../variables" as *;

// <header>
// Hidden on mobile (app) and when in "full screen" views
.web-header {
  --link: #{$theme-header-text};
  --hover: #{$theme-button-text};

  .default-theme & {
    --hover: #{$tt-blue};
  }

  // No header on mobile
  @include mq.max-width-sm {
    display: none;
  }

  position: sticky;
  top: 0;
  z-index: $zindex-sticky + 1; // Position over sticky segment headers
  display: flex;
  gap: var(--tt-gap);
  height: $web-header-height;
  padding-inline: var(--tt-gap);
  background: $theme-header;
  box-shadow: $web-header-shadow;

  > a,
  > * > a {
    &:hover,
    &:focus-visible {
      opacity: 0.72;
    }
  }

  // Logo as home link
  [rel="home"] {
    // Push logo down by computed speech tail height, center aligning the balloon part
    padding-block-start: 4px;
    fill: currentcolor;

    .default-theme & {
      fill: $tt-blue;
    }

    svg {
      width: auto;
      height: $logo-size;
    }
  }
}

// Pushes previous siblings to the left, next sibling menus to the right
.web-header-spacer {
  flex: 1;
}
