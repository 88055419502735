/// Page layout blocks
// .page-wrapper
//   header.web-header          z: 1000  Hidden on mobile and when in "full screen" views
//   .page-content#content
//     section.alert-group
//     (nav.navigation)           Program navigation
//     [content]                  Segments, single column, two-column, exercises

@use "../variables" as *;

// Wrapping all content in the page
// .page-wrapper {}

.page-content {
  display: flex;
  flex-direction: column;

  // Option 1: Causes scrollbar when content < 100vh
  // min-height: 100dvh;

  // Option 2: Fullscreen content doesn't fill all the way to the bottom
  // => Lesser of 2 evils
  min-height: calc(100dvh - $web-header-height);
}
