/// User info
// Note: class .user-info instead of simpler .user:
// Ruby outputs class .user on elements, e.g. `[coach]` in `.card.-large[coach]`

// @markup
// .user-info
//   <img|span>.avatar
//   <h4>
//   (<time|span)

@use "../variables" as *;

.user-info {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0 $inline-gap-lg;
  align-items: center;
  color: $theme-neutral;

  h4 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // <time|span>
  h4 + * {
    font-size: $font-size-sm;
  }
}
