/// Content blocks
// Grouping content blocks inside main content blocks, like layout columns and modals.
// Containing descriptions, videos, other components.
// Has its own, smaller inner spacing compared to main wrapper

// <section|div>.content        Prefer <section> when it makes semantic sense
//   (.content-inner)           Optional: when needing to group elements inside .content
//     <h2>                     E.g. "Your anwer", "Feedback"
//     <h3>                     E.g. "Situation", "Assignment"
//     {content}

// Example 1: RolePlay details
// .main-content
//   section.content    RP description
//   section.content    RP exercise
//   section.content    RP user response
//   section.content    RP feedback

// Example 2: Assessment report
// .main-content
//   section.content    Assessment total score + coach feedback
//   section.content    Assessment scores per competence

@use "../variables" as *;

// <section|div>
.content {
  display: grid;
  grid-auto-rows: max-content;
  gap: var(--tt-gap);

  &.-centered {
    width: min(100%, $layout-content-max-width);
    margin-inline: auto;
  }
}

// Inside .content, groups block of related content which are not in a card
// E.g. Situation+description + Assignment+description
// .content-inner {}
