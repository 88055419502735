/// Override Bootstrap where variables aren't available

@use "../_variables/" as *;

// Closing × in modals and alerts
// <button>.close
// ~ .button
.close {
  width: $button-height;
  height: $button-height;
  aspect-ratio: 1;
  cursor: pointer;
  background: transparent;
  border: 0;
  box-shadow: none;

  i {
    font-size: $font-size * 2;
  }

  .alert &,
  .modal & {
    position: absolute;
    top: 0 + 2px; // Adjust for Bootstrap's margin-top: -2px
    right: 0;
    z-index: 1;
  }

  &:hover,
  &:focus {
    --c: #{$gray-900};
  }
}

/// Collapsible blocks
// <button|a>
[data-toggle="collapse"] {
  display: flex;
  gap: $inline-gap;
  justify-content: space-between;
  cursor: pointer; // <button> doesn't get pointer

  i {
    align-self: center;
    font-size: $icon-font-size-lg;
    transition: transform 120ms ease-in;
    transform: rotate(var(--r, 0));
  }

  &[aria-expanded="true"] {
    --r: 180deg;
  }
}

/// Dropdown menus
// BS3: [data-toggle="dropdown"]
// BS5: [data-bs-toggle="dropdown"]
// <button|a>
.dropdown-toggle {
  // Remove Bootstrap5 chevron
  &::after {
    display: none;
  }
}

// <menu|ul>
.dropdown-menu {
  width: max-content;
  max-width: 20rem;
  padding: $gap-sm;

  // @TEMP: This forces BS5 script to work with BS3 style
  // BS3: .open
  &.show {
    display: block;
  }

  // > li > a
  .dropdown-header,
  .dropdown-item {
    display: flex;
    gap: $inline-gap-lg;
    align-items: center;
    padding: $gap-sm;
    font-size: $font-size;
    border-radius: $border-radius-xxs;
  }

  .dropdown-header {
    font-weight: 600;
  }

  .dropdown-divider {
    height: 1px;
    margin: $gap-sm;
    overflow: hidden;
    background: $border-color;
  }

  // Environment menu description
  small {
    color: $theme-neutral;

    // Forces to wrap long descriptions
    white-space: initial;
  }
}
