/// All top-level :root styles

@use "@functions/units" as unit;
@use "../_mixins/mq" as mq;
@use "../_mixins/branding" as branding;
@use "../variables" as *;

:root {
  // "boolean" variables
  --ON: initial; // Forcing var() fallback
  --OFF: ""; // Causes IACVT => unset

  // Branding theme's custom properties
  @include branding.branding-theme;

  // Global spacing: padding, margin, gap etc.
  // --tt-gap: #{$gap};
  // --tt-gap-sm: #{$gap-sm};
  // --tt-gap-lg: #{$gap-lg};
  --tt-gap: #{$gap-lg};
  --tt-gap-sm: #{$gap};
  --tt-gap-lg: #{$gap-2xl};

  @include mq.min-width-sm {
    // Sticky elements to stick underneath .web-header
    --sticky-header-height: #{$web-header-height};

    // Increase global spacing on larger screens
    --tt-gap: #{$gap-lg};
    --tt-gap-sm: #{$gap};
    --tt-gap-lg: #{$gap-2xl};
  }
}
