/// Flags on cards
// Contain icon or avatar

@use "@functions/color-transparency" as transparency;
@use "../variables" as *;

$_card-flag-icon-computed-width: 24px; // $avatar-size-xs/.fa computed width
$_card-flag-padding: 6px;
$_card-flag-width: $_card-flag-icon-computed-width + $_card-flag-padding * 2;
$_card-flag-overhang: 5px;
$_card-flag-tails-height: 10px;
$_card-flag-theme: $theme-main;

.card-flag {
  position: absolute;
  top: -$_card-flag-overhang;
  right: 2.5rem; // Just a nice distance
  z-index: $card-zindex-flag;

  // Small triangle "folding over the card"
  &::before {
    position: absolute;
    top: -$_card-flag-overhang;
    left: -$_card-flag-overhang;
    z-index: -1;
    content: "";
    border: $_card-flag-overhang solid transparent;
    border-bottom-color: var(--flag-theme, $_card-flag-theme);
  }

  // Already joined program
  &.-joined {
    --flag-theme: #{$theme-success};
  }

  // New skill or 100% score
  &.-bonus {
    --flag-theme: #{$theme-warning};
  }

  // Coach recommends follow-up program
  &.-recommended {
    --flag-theme: #{$coach-color};
  }
}

.card-flag-inner {
  display: flex;
  justify-content: center;
  padding: 14px $_card-flag-padding 12px;
  font-size: $_card-flag-icon-computed-width; // For .fa
  color: $white;
  background-color: var(--flag-theme, $_card-flag-theme);
  background-image: linear-gradient(
    transparency.white(0%),
    transparency.white(32%) 1px,
    transparency.white(0%) 14px
  );
  filter: drop-shadow(-1px 2px 2px transparency.black(24%));
  border-top-right-radius: 2px;

  // Tails underneath the flag
  &::after {
    position: absolute;
    bottom: -$_card-flag-tails-height;
    left: 0;
    content: "";
    border-inline: ($_card-flag-width * 0.5) solid var(--flag-theme, $_card-flag-theme);
    border-bottom: $_card-flag-tails-height solid transparent;
  }
}
