/// Forms
// <form>.simple_form
//   <fieldset|div>.form-group
//     <label>.control-label
//     <input|textarea|select>.form-control

@use "@functions/map";
@use "../_mixins/mq" as mq;
@use "../variables" as *;

fieldset {
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  h4 {
    margin-block: 0;
  }
}

// Not used
// legend.sr-only {}

label {
  // Simply remove ugly 'required' asterisk *
  > abbr {
    display: none;
  }
}

// NOTE: input can also be submit button. BS doesn't assign a class to it.
input,
textarea,
select,
button {
  font: inherit;
}

// @TODO: this also includes type="range" for VideoPlayer progress!
input,
textarea,
select {
  width: 100%;
  height: auto;
  padding: $input-padding-block $input-padding-inline;
  line-height: $input-line-height;
  color: $theme-text;
  background: $white;
  border: $border-width solid var(--input-theme-brd, $input-default-border);
  border-radius: $input-border-radius;
  box-shadow:
    0 0 0 12px $theme-tint,
    0 2px 2px var(--input-theme),
    0 4px 9px var(--input-theme);
  transition: $interaction-animation-speed $animation-timing;
  transition-property: border-color, box-shadow;

  @include mq.retina {
    border-width: $border-width-retina;
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    --input-theme: #{map.deep-get($input-themes, "focus")} !important;

    outline: 0;
  }

  // Prefer attribute over classes .disabled, .-disabled
  // vs &:disabled,
  &[disabled],
  fieldset[disabled] & {
    pointer-events: none;
    cursor: not-allowed;
    background: $tt-gray-200;
  }

  // vs &:read-only
  &[readonly] {
    // pointer-events: initial;
    // cursor: initial;
    background: $tt-gray-200;
  }

  &::placeholder {
    font-style: italic;
    color: $theme-neutral;
  }
}

// Notes
// Custom autocue
// Peer feedback
textarea {
  max-height: 24em; // Note: this doesn't consider padding or line-height
  resize: vertical;
}

// Radio buttons:
// <label>
//   <input type="radio">
//   <label>
input:where([type="checkbox"], [type="radio"]) {
  // WebKit-specific. Other browsers will keep their default outline style.
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;

  + label {
    margin-block-end: 0;
  }
}

// Profile avatar
// Has .sr-only hiding it
// input[type="file"] {}

/// Classes by Bootstrap via Simple Form

// <fieldset|div> wrapping related label(s) and form element(s)
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $line-height-xs;
  margin-block-end: $line-height-sm; // Why or why not?

  // Wrappers are disabled by default
  // &.has-error {
  //   --input-theme: #{map.deep-get($input-themes, "error", "theme")};
  //   --input-theme-brd: #{map.deep-get($input-themes, "error", "border")};
  // }
}

// <label>
.control-label {
  // Positions input:focus-visible shadow fx underneath
  position: relative;
  z-index: 1;

  // margin-block-end: $line-height-xs; // => gap on parent
}

// <input|textarea|select>
//   ↳ type=["text"|"email"|"password"|"search"]
.form-control {
  &[aria-invalid="true"] {
    --input-theme: #{map.deep-get($input-themes, "error", "theme")};
    --input-theme-brd: #{map.deep-get($input-themes, "error", "border")};
  }

  // See help.scss
  // Bootstrap's .help-block
  + .error {
    padding-inline: $input-padding-inline;
  }
}

// Lock icon for disabled/readonly fields
// @TODO?: Eye icon for showing/hiding password
// <i>
.form-control-icon {
  position: absolute;
  top: 50%;
  right: $input-padding-inline;
  color: $theme-neutral;
  transform: translateY(-50%);
}
