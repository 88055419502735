/// Main layout: 1-2 columns, optional sidebar
// Programs (dashboard)
// Information segment
// RolePlay
// Preparation
// Segment result > tab Assessment

/// 3 flavours:
// .main-wrapper
//   main.main-overview         Index: programs, segments
//     {content}
///
// .main-wrapper
//   main.main-content          Details: RolePlay, info
//     {content}
//   (aside.main-sidebar)
///
// .main-wrapper
//   main.main-fullscreen       Activity: preparation, exercise
//     {content}

@use "../_mixins/mq" as mq;
@use "../variables" as *;

// Wraps <main> content and <aside>bar
.main-wrapper {
  display: flex; // Column+gap should collapse when no sidebar present
  flex-direction: var(--main-layout, row);
  gap: var(--tt-gap);
  justify-content: center;
  padding: var(--tt-gap);

  &.-columns {
    --main-layout: column;

    @include mq.min-width-sm {
      --main-layout: row;
      --main-content-size: 0 #{$layout-content-ratio} #{$layout-content-max-width};
      --main-sidebar-size: 0 #{$layout-sidebar-ratio} #{$layout-sidebar-max-width};
    }
  }

  // &.-overview {}

  &.-fullscreen {
    flex: 1;

    // width: 100%;
    // height: 100%;
  }

  &:not(.-fullscreen) {
    // Large space at the bottom, being nice to mobile users when swiped
    // all the way up and not have to twiddle at the bottom of the screen.
    padding-block-end: $gap-2xl;
  }
}

/// Main content

main {
  display: grid;
  gap: var(--tt-gap-lg);
  align-items: start;

  @include mq.min-width-sm {
    flex: var(--main-content-size, 0 #{$layout-main-ratio} #{$layout-main-max-width});
  }

  // On some pages a form wraps all content, let layout styles pass through
  > form {
    display: contents;
  }
}

// All detail pages
// .main-content {}

// Programs index
// Program details (segments timeline)
// .main-overview {}

// Preparation, PlayRecord, Branching
.main-fullscreen {
  // First content block will stretch height of the page
  // Following block will be at the bottom (e.g. buttons)
  // grid-template-rows: 1fr; // min-content;

  // Prevents stretching children height-wise
  // align-items: start;
}

/// Sidebar, with sticky content

// <aside>
.main-sidebar {
  flex: var(--main-sidebar-size);

  @include mq.min-height-md {
    // = .card-list
    > * {
      position: sticky;
      top: calc(var(--sticky-header-height, 0) + var(--tt-gap));
    }
  }
}
