/// Wells
// Wraps related content inside layout, with a background color (Gestalt Principle)
// As header or inside a .card

@use "../variables" as *;

// <div>
.well {
  padding: var(--tt-gap);
  background: $tt-gray-200;
  border-radius: $border-radius;

  .card & {
    padding: var(--tt-gap-sm);
    background: $tt-gray-100;
  }
}
