/// Various helpers

@use "../_mixins/mq" as mq;
@use "../variables" as *;

.hidden-screen-xs {
  @include mq.max-width-xs {
    display: none;
  }
}

// Keyboard keys for autocue tip
.keyboard-key {
  display: inline-block;
  width: 2em;
  height: 1.75em;
  padding: 0.25em;
  margin: 0 0.125em;
  font-size: 1rem;
  line-height: 1;
  color: $gray-700;
  text-align: center;
  background-color: $gray-100;
  border: 1px solid $gray-700;
  border-bottom-width: 2px;
  border-radius: 3px;
}

/// From Bootstrap scaffolding:

.hidden {
  display: none !important;
}

// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

// Not all <a> might have href attribute
// Not all buttons might be a <button>
// iOS "clickable elements" fix for role="button"
//   Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
//   for traditionally non-focusable elements with role="button"
//   see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
  cursor: pointer;
}

.pre-wrap {
  white-space: pre-wrap;
}
