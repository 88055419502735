/// Rate this program 1..10

// @markup
// fieldset[data-review-target="question"]
//   .review-grades
//     input[type="radio"]
//     label[data-review-target="grade"].button.-circle
//   .review-comment
//     label[data-review-target="prompt"]
//     textarea[data-review-target="comment"]
//   .review-consent[data-review-target="consent"]
//     label[data-review-target="prompt"]
//     input[type="checkbox"]
//   .review-store[data-review-target="storeReview"]
// button[data-review-target="submit"]

@use "../_mixins/mq" as mq;
@use "../variables" as *;

.review-grades {
  display: grid;
  grid-template-columns: repeat(var(--grades-per-row, 5), auto);
  gap: $inline-gap;
  justify-content: center;
  margin-block-end: var(--tt-gap-sm);

  @include mq.min-width-xs {
    --grades-per-row: 10;
  }

  // = .button
  label {
    position: relative;
    cursor: pointer;
  }

  // input[type="radio"]
  input {
    // Hide radio button; Copied from Bootstrap 3
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:focus-visible {
      + label {
        outline: 2px auto dodgerblue;
        outline-offset: 2px;
      }
    }
  }
}

// .review-comment {}
// .review-consent {}
// .review-store {}
