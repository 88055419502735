/// Filters for events, notifications
// .filter.dropdown
//   button
//   menu.dropdown-menu
//   a.button

@use "../variables" as *;

// Identical to .search
.filter {
  width: min(100%, $component-max-width);
  margin-inline: auto;
}

// Similar to .search-bar
.filter-bar {
  display: grid;
  grid-template-columns: 1fr auto;

  menu {
    width: 100%;
    max-width: none; // Override .dropdown-menu
  }

  // Button that opens the filter menu: push icon to the end
  button {
    justify-content: space-between;
    width: 100%;
    border-radius: $card-border-radius;
  }

  // <a> Clear current filters
  // role="button"
  > a {
    --btn-bg: none;
    --btn-shadow: none;

    i {
      font-size: $icon-font-size-lg;
    }
  }
}
