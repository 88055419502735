/// Branching RolePlays

@use "@functions/color-transparency" as transparency;
@use "@functions/units" as unit;
@use "../variables" as *;

@keyframes brp-loader {
  0% {
    right: 100%;
  }

  100% {
    right: 0;
  }
}

// React wrapper - Faux fullscreen
// [data-react-component="BranchingPlayer"]
.branchingplayer {
  display: contents;
}

.brp-loader {
  position: absolute;
  inset: 0 100% 0 0;
  background: $progress-default-theme;
  animation: brp-loader 1300ms $animation-timing;
}

.brp-over-video {
  position: absolute;
  inset: 0;
  padding: 4rem 3rem 1rem;
  background-color: transparency.white(80%);
  backdrop-filter: blur(4px);
}

.brp-appear {
  animation: fade-in 1s $animation-timing;
}

.brp-spinner {
  width: 1.25em;
  height: 1.25em;
  border: 0.1875em solid $gray-100;
  border-top: 0.1875em solid $progress-default-theme;
}
